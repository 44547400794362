/*Custom styling file for patient side*/

.heading {
    color: #005d38;
}

.error-message-field-generic {
    color: rgb(177, 6, 6);
    font-weight: 500;
}

.primary_text {
    color: #7A7A7A;
    font-weight: 500;
    font-size: clamp(12px, 3.5vw, 14px);
}

.text-start {
    font-size: 18px;
}
.sidebar_menu_icon{
font-size: 20px;
}
.No_data_warning_text {
    font-size: 18px;
    font-weight: 600;
    color: rgb(177, 6, 6);
    font-family: 'Courier New', Courier, monospace;
}
.red_astaric{
    color: red !important;
}
.astaric_span{
    font-weight: 600;
}
.pricing_warning_text{
    font-size: clamp(.5rem,2.5vw,0.75rem);
    font-weight: 500;
    color: #00000070;
}
.pricing_notice_text{
    background-color: #c0000029;
    border-radius: 0.25rem;
}
.content_body {
    /* background-image: url(../../assets/images/Sage_body_bg.png); */
    background-image: url(../../assets/images/bg_1.jpg);
    position: absolute;
    width: 100%;
    height: 100%;
    /* height: min(970px, 117vh, 8910px); */
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed;  */
}

.doc_image {
    top: 0;
    right: 0;
    position: absolute;
}

.sage_mini_logo {
    top: 30px;
    right: 50px;
    position: absolute;
    background-color: #005d38;
    width: fit-content;
    padding: 10px 10px;
    border-radius: 50%;
}

.sage_mini_logo .mini_logo {
    width: 100px;
    height: 100px;
}

.doc_image .rightside_image {
    /* width: 95.2%;
    height: 100%; */
    /* width: 800px; */
    width: min(1600px, 42vw, 8910px);
    height: min(970px, 100vh, 8910px);

}

/* .Subscription_card_box {
    position: relative;
    top: 60px;
    left: 30px;
    width: 90%;
} */

/* .custom_card_inner_styling_signUp_page {
    position: relative;
    top: 120px;
    left: 200px;
    padding: 0px 50px;
} */

/* .card_box_div{
    width: 35%;
} */

.custom_card_inner_styling_login_page,
.custom_card_inner_styling_signUp_page {
    box-shadow: 0 0 20px #00000033;
    border-radius: 10px;
    background-color: #ffffff50;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.custom_card_inner_styling_verifyOtp_signUp_page {
    box-shadow: 0 0 20px #00000033;
    border-radius: 10px;
    background-color: #ffffff50;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    
}

.Email_otp_card {
    box-shadow: 0 0 20px #00000033;
    border-radius: 10px;
    /* width: 20rem; */
}

.subsciption_heading_div {
    margin-top: 20px;
    text-align: center;
}

.myPlan_subsciption_heading_div {
    margin-top: 0px;
    text-align: center;
}

.Login_heading_div h1 {
    text-align: center;
    color: #005d38;
    font-weight: 500;
    font-family: 'Roboto';
}

.Login_sub_heading_div {

    text-align: center;
}

.Email_otp_sub_heading_div {
    text-align: center;
}

.Email_otp_sub_text_div {
    text-align: center;
}

.Email_otp_sub_text_div>span {
    font-size: 1.4rem;
    color: #515151;
    font-family: Roboto-Medium;
    font-weight: 500;
}

.Email_otp_sub_heading_div>span {
    color: #005d38;
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 28px;
}

.Login_sub_heading_div>span {
    color: #005d38;
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 24px;
}

.subsciption_heading_div h1 {
    text-align: center;
    color: #005d38;
    font-size: 50px;
    font-weight: 500;
    font-family: 'Roboto-Medium';
}

.myPlan_subsciption_heading_div>h2 {
    text-align: center;
    color: #5A5A5A;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Roboto-Medium';
}

.subsciption_heading_div h2 {
    text-align: center;
    color: #515151;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Roboto';
}

.custom_card_styling_Subscription_page {
    box-shadow: 0 0 20px #00000033;
    border-radius: 10px;
    width: 100%;
    background-color: #ffffff50;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.custom_card_styling_login_page {
    box-shadow: 0 0 20px #00000033;
    border-radius: 10px;
    height: 80vh;
}

.custom_card_inner_styling_subscrition_page {
    box-shadow: 0 0 12px #00000029;
    border-radius: 20px;
    /* margin: 0px 10px; */
    width: 100%;
}

.custom_card_inner_styling_subscrition_page:hover {
    box-shadow: 0 0 12px #00000029;
    border-radius: 20px;
    /* margin: 0px 10px; */
    border: 2px solid #005d38;
    transition: all 0.6s ease-in-out;
    cursor: pointer;
}

.Gold_Image_box .goldBg {
    width: 100%;
}

.Gold_Image_box .goldTag {
    width: 30%;
    right: 0;
    right: 20px;
    position: absolute;
}

.silver_Image_box .silverBg {
    width: 100%;
}

.silver_Image_box .silverTag {
    width: 30%;
    right: 0;
    right: 20px;
    position: absolute;
}

.pricing_div {
    top: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.Starting_from_span {
    margin: 0px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.Price_span {
    margin: 10px 20px;
    font-size: 36px;
    font-weight: 500;
    color: #000000;
    font-family: "Roboto, Medium Italic";
}

.offer_list_div>ul {
    padding: 0px 20px;
}

.offer_list_div>ul>li {
    list-style: none;
    margin-top: 10px;
    font-size: clamp(0.75rem, 2.5vw, 0.9rem);
}

.offer_list_div .gold_check_icon {
    color: #005d38;
    font-size: 18px;
    margin-right: 5px;
}

.offer_list_div .gold_close_icon {
    color: #FF4A4A;
    font-size: 18px;
    margin-right: 5px;
}

.plan_button_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0px 20px 0px;
}

.plan_button_div .chooseplan_button_gold {
    color: #000000;
    font-size: 18px;
    font-family: 'Roboto';
    background: linear-gradient(#FBEE95, #E3BA62);
    border: 1px solid #FBEE95;
    padding: 5px 50px;
}

.custom_card_inner_styling_subscrition_page:hover .chooseplan_button_gold {
    color: #000000;
    transform: scale(1.05);
    font-family: 'Roboto';
    background: linear-gradient(#FBEE95, #E3BA62);
    border: 1px solid #FBEE95;
    padding: 5px 50px;
}

.plan_button_div .chooseplan_button_silver {
    color: #000000;
    font-size: 18px;
    font-family: 'Roboto';
    background: linear-gradient(#F2F2F2, #CECECE);
    border: 1px solid #F2F2F2;
    padding: 5px 50px;
}

.custom_card_inner_styling_subscrition_page:hover .chooseplan_button_silver {
    color: #000000;
    font-size: 18px;
    font-family: 'Roboto';
    background: linear-gradient(#F2F2F2, #CECECE);
    border: 1px solid #F2F2F2;
    padding: 5px 50px;
    transform: scale(1.05);
}

.gold_price_Modal .modal-content {
    border-radius: 15px;
}

.gold_pricing_popup {
    background-color: #005d38;
    color: #ffffff;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.gold_pricing_popup .close {
    color: #ffffff;
}

.verify_Otp_popup {
    background-color: #005d38;
    color: #ffffff;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.verify_Otp_popup .close {
    color: #ffffff;
}

.price_popp_card_1 {
    background-color: #45bc8c20;
    border: none !important;
}

.price_popp_card_2 {
    background-color: #2B79B415;
    border: none !important;
}

.price_popp_card_3 {
    background-color: #FF994A15;
    border: none !important;
}

.price_popp_card_1:hover {
    background-color: #45bc8c;
    border: none !important;
    cursor: pointer;
}

.price_popp_card_2:hover {
    background-color: #2B79B4;
    border: none !important;
    cursor: pointer;
}

.price_popp_card_3:hover {
    background-color: #FF994A;
    border: none !important;
    cursor: pointer;
}

.price_popp_card_1:hover .pricing_popup_card_header_div {
    background-color: #ffffff;
}

.price_popp_card_1:hover .pricing_popup_card_header_div span {
    color: #005d38;
}

.price_popp_card_1:hover .pricing_popup_card_price_div span {
    color: #fff;
}

.price_popp_card_1:hover .pricing_popup_card_text_div span {
    color: #fff;
}

.price_popp_card_1:hover .pricing_popup_card_doted_div {
    border-top: 2px dashed #fff;
}

.price_popp_card_2:hover .pricing_popup_card_header_div_1 {
    background-color: #ffffff;
}

.price_popp_card_2:hover .pricing_popup_card_header_div_1 span {
    color: #2B79B4;
}

.price_popp_card_2:hover .pricing_popup_card_price_div_1 span {
    color: #fff;
}

.price_popp_card_2:hover .pricing_popup_card_text_div_1 span {
    color: #fff;
}

.price_popp_card_2:hover .pricing_popup_card_doted_div {
    border-top: 2px dashed #fff;
}

.price_popp_card_2:hover .discount_span {
    color: #ffffff;
}

.price_popp_card_3:hover .pricing_popup_card_header_div_2 {
    background-color: #ffffff;
}

.price_popp_card_3:hover .pricing_popup_card_header_div_2 span {
    color: #FF994A;
}

.price_popp_card_3:hover .pricing_popup_card_price_div_2 span {
    color: #fff;
}

.price_popp_card_3:hover .pricing_popup_card_text_div_2 span {
    color: #fff;
}

.price_popp_card_3:hover .pricing_popup_card_doted_div {
    border-top: 2px dashed #fff;
}

.price_popp_card_3:hover .discount_span_1 {
    color: #ffffff;
}

.pricing_popup_card_header_div {
    background-color: #45BC8D;
    padding: 15px 0px;
    text-align: center;
    border-radius: 3px;
}

.pricing_popup_card_header_div_1 {
    background-color: #2B79B4;
    padding: 15px 0px;
    text-align: center;
    border-radius: 3px;
}

.pricing_popup_card_header_div_2 {
    background-color: #FF994A;
    padding: 15px 0px;
    text-align: center;
    border-radius: 3px;
}

.pricing_popup_card_header_div span {
    color: #ffffff;
    font-size: 24px;
    font-family: Roboto, Medium;
    font-weight: 500;
}

.pricing_popup_card_header_div_1 span {
    color: #ffffff;
    font-size: 24px;
    font-family: Roboto, Medium;
    font-weight: 500;
}

.pricing_popup_card_header_div_2 span {
    color: #ffffff;
    font-size: 24px;
    font-family: Roboto, Medium;
    font-weight: 500;
}

.pricing_popup_card_price_div {
    padding: 15px 0px;
    text-align: center;
    border-radius: 3px;
    margin: 30px 0px;
}

.pricing_popup_card_price_div_1 {
    padding: 15px 0px;
    text-align: center;
    border-radius: 3px;
    margin: 30px 0px;
}

.pricing_popup_card_price_div_2 {
    padding: 15px 0px;
    text-align: center;
    border-radius: 3px;
    margin: 30px 0px;
}

.pricing_popup_card_price_div span {
    color: #45BC8D;
    padding: 15px 0px;
    text-align: center;
    font-size: clamp(2.5rem,5.5vw,3.3rem);
    font-weight: 500;
    font-family: 'Roboto, Medium';
}

.pricing_popup_card_price_div_1 span {
    color: #2B79B4;
    padding: 15px 0px;
    text-align: center;
    font-size: clamp(2.5rem,5.5vw,3.3rem);
    font-weight: 500;
    font-family: 'Roboto, Medium';
}

.pricing_popup_card_price_div_2 span {
    color: #FF994A;
    padding: 15px 0px;
    text-align: center;
    font-size: clamp(2.5rem,5.5vw,3.3rem);
    font-weight: 500;
    font-family: 'Roboto, Medium';
}

.discount_span {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    display: flex;
    margin-top: -15px;
}

.discount_span_1 {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    display: flex;
    margin-top: -15px;
}

.pricing_popup_card_doted_div {
    border-top: 2px dashed #515151;
}

.pricing_popup_card_text_div {
    padding: 15px 0px 0px 0px;
    text-align: center;
}

.pricing_popup_card_text_div_1 {
    padding: 15px 0px 38px 0px;
    text-align: center;
}

.pricing_popup_card_text_div_2 {
    padding: 15px 0px 38px 0px;
    text-align: center;
}

.pricing_popup_card_text_div span {
    color: #000000;
    font-family: 'Roboto, Regular';
    font-size: clamp(1rem,2.5vw,1.24rem);
}
.pricing_popup_card_text_div_1 span {
    color: #000000;
    font-family: 'Roboto, Regular';
    font-size: clamp(1rem,2.5vw,1.15rem);
}
.pricing_popup_card_text_div_2 span {
    color: #000000;
    font-family: 'Roboto, Regular';
    font-size: clamp(1rem,2.5vw,1.15rem);
}

.signUp_button {
    background-color: #83B42B;
    border: 1px solid #83B42B;
    width: 100%;
    height: 50px;
    font-size: 22px;
}

.signUp_button:hover,
.signUp_button:focus,
.signUp_button.active,
.signUp_button:active {
    background-color: #005d38;
    border: 1px solid #005d38;
}

.link_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
}

.link_div .signIn_link {
    font-weight: 600;
    color: #005d38;
    cursor: pointer;
    font-size: 18px;
}

.PhoneInputInput {
    border: none
}
.PhoneInputInput--focus.PhoneInputInput:focus {
    border: none !important;
}

.inputField_icons {
    position: absolute;
    top: 17px;
    left: 25px;
    color: #828282;
}

.Login_verifyOtp_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.custom_card_VerficationScreen {
    box-shadow: 0 0 20px #00000033;
    border-radius: 10px;
    width: 20rem;
}

.opt_input_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.opt_input_div .styles_react-code-input__CRulA>input {
    border-top: 0px;
    border-left: 0;
    border-right: 0;
    inset-inline: 5px;
    border-bottom: 2px solid #000;
    border-radius: 0;
    margin-right: 10px;
    width: 40px !important;
    height: 40px !important;
    font-size: 30px;
    color: #525461;
    background-color: #ebebeba4;
    /* border-radius:10px 10px 0px 0px; */
}

.payment_Header_div {
    /* background: #005d38; */
    background: linear-gradient(180deg, #078855 0%, #005d38 100%);
    box-shadow: 0px 0px 12px #00000029;
    width: 90%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    display: flex;
    flex-direction: row;
}

.header_logo {
    padding: 10px 10px;
}

.header_logo>.header_logo_img {
    width: 90px;
    height: 70px;
}

.header_heading {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
}

.header_heading>.header_heading_text {
    color: #fff;
    font-size: 30px;
    font-family: Poppins-regular;
}

.payment_method_div {
    /* top: 5rem;
    position: relative; */
    border-top: 2px solid #005d38;
    margin: 5rem 70px 20px 70px;
}

.payment_details_div {
    /* top: 5rem;
    position: relative; */
    border-top: 2px solid #005d38;
    margin: 0.25rem 70px 20px 70px;
}

.payment_details_div>.method_heading {
    width: fit-content;
    background-color: #005d38;
    padding: 10px 70px 10px 50px;
    border-bottom-right-radius: 5rem;
}

.payment_details_div>.method_heading>.methods_heading_text {
    background-color: #005d38;
    color: #fff;
    font-size: 20px;
    font-family: Poppins-regular;
}

.payment_method_div>.method_heading {
    width: fit-content;
    background-color: #005d38;
    padding: 10px 70px 10px 50px;
    border-bottom-right-radius: 5rem;

}

.payment_method_div>.method_heading>.methods_heading_text {
    background-color: #005d38;
    color: #fff;
    font-size: 20px;
    font-family: Poppins-regular;
}

.payment_card_div {
    margin: 0.25rem 70px 20px 70px;
}

.cards_images_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.cards_images_div>img {
    box-shadow: 0px 0px 6px #0000008c;
}

.cards_images_div>img:hover {
    border: 1px solid #005d38;
}

.credit_cards_image_visa {
    width: 100px;
    height: 67px;
    border-radius: 10px;
}

.credit_cards_image_master {
    width: 100px;
    height: 67px;
    margin-left: 20px;
    border-radius: 10px;
}

.credit_cards_image_paypal {
    width: 100px;
    height: 67px;
    margin-left: 20px;
    border-radius: 10px;
}

.payment_form_div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: 70px;
    margin-right: 70px;
}

.payment_Button_form_div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: 70px;
    margin-right: 70px;
}

.label_div {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.label_div>.form_labels {
    color: #000000;
    font-weight: bold;
    font-size: 17px;
    margin: 0;
}

.label_div>.form_info_label {
    color: #B3B3B3;
    font-weight: 400;
    font-size: 14px;
}

.input_div {
    width: 50%;
}

.date_div {
    display: flex;
    flex-direction: row;
}

.month_picker {
    width: 90%;
}

.credit_card_icons {
    color: #8f8f8f;
    top: 15px;
    right: 100px;
    position: absolute;
}

.PayNow_button {
    background-color: #83B42B;
    border: 1px solid #83B42B;
    width: min(30vw, 170px);
    height: 50px;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
}

.PayNow_button:hover,
.PayNow_button:focus,
.PayNow_button.active,
.PayNow_button:active {
    background-color: #005d38;
    border: 1px solid #005d38;
}

.right_side_box {
    background-color: #005D380D;
    border: 1px solid #005d38;
    border-radius: 10px;
    /* margin-top: 80px; */
    /* width: min(1600px,100%,8910px); */
    display: flex;
    flex-direction: column;
}

.right_side_subcribed_payment_box {
    background-color: #005D380D;
    border: 1px solid #005d38;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
}

.right_side_box_dashboard {
    background-color: #005D380D;
    border: 1px solid #005d38;
    border-radius: 10px;
    width: 80%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
}

.my_plan_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.your_plan_image,
.choosed_plan_image {
    width: 70%;
}

.your_plan_text {
    color: #000000;
    font-size: 28px;
    width: 100%;
    font-family: Roboto-Medium;

}

.choosed_plan_text {
    color: #000000;
    font-size: 24px;
    /* width: 100%; */
    font-family: Roboto-Medium;

}

.side_bar_vline {
    border-top: 1px dashed #005D38;
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.side_bar_vline_choosed_plan {
    border-top: 1px dashed #005D38;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.subs_text_div {
    display: flex;
    flex-direction: column;
}

.Price_text_div {
    display: flex;
    flex-direction: column;
}

.subs_text_div>label {
    color: #515151;
    font-weight: 500;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    margin-left: 20px;
    margin-bottom: 0px;
}

.Price_text_div>label {
    color: #515151;
    font-weight: 500;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    margin-left: 20px;
    margin-bottom: 0px;
}

.choosed_plan_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 20px 10px 20px;
}

.choosed_plan__price_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px 20px 20px 20px;
}

.choosed_plan_div>span {
    background: linear-gradient(90deg, #E3BA62 0%, #FBEE95 50%, #E3BA62 100%);
    border-radius: 10px;
    color: #000000;
    font-weight: 500;
    width: 100%;
    font-size: clamp(1rem, 2.5vw, 1.75rem);
    padding: 10px 20px;
    text-align: center;
    font-family: Roboto-Medium;
}

.choosed_plan_div>.platinum {
    background: linear-gradient(90deg, #9b9995 0%, #e2e2de 50%, #afafaf 100%);
    border-radius: 10px;
    color: #000000;
    font-weight: 500;
    width: 100%;
    font-size: clamp(1rem, 2.5vw, 1.75rem);
    padding: 10px 20px;
    text-align: center;
    font-family: Roboto-Medium;
}

.choosed_plan__price_div>span {
    background: #005D3840;
    border-radius: 10px;
    color: #000000;
    font-weight: 600;
    width: 100%;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    padding: 10px 20px;
    text-align: center;
    font-family: Roboto-Medium;
}

.dashboard_heading {
    color: #5A5A5A;
    font-family: 'Roboto,Medium';
    font-weight: 600;
}

.dashboard_sub_heading {
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}

.choosed_plan_table_div {
    padding: 10px 0px;
    background-color: #ffff;
    padding: 0px 15px;
    border-radius: 1rem;
    box-shadow: 0px 0px 3px 3px #cdcdcd;
}

.choosed_plan_table_div .table {
    border-collapse: separate;
    border-spacing: 0;
    -webkit-border-vertical-spacing: 15px !important;
    border-radius: 1rem;
}

.choosed_plan_table_div>.table thead tr th:first-child {
    border-top-left-radius: 0.5rem;
}

.choosed_plan_table_div>.table thead tr th:last-child {
    border-top-right-radius: 0.5rem;
}

.choosed_plan_table_div>.table thead tr th {
    border: 1px solid #efefef;
    background-color: #005d38;
    color: #fff;
    text-align: center;
}


.choosed_plan_table_div .table td {
    vertical-align: middle;
    padding-right: 2px;
}

.choosed_plan_table_div .table tbody tr {
    box-shadow: 0px 0px 3px 2px #b8b8b8;
    border-radius: 0.5rem;
    font-weight: 500;
}

.choosed_plan_table_div .table>tbody>tr>td:nth-child(3) {
    text-align: left;
}

.choosed_plan_table_div .table>tbody>tr>td {
    text-align: center;
}

.react-bootstrap-table-pagination {
    display: flex;
    flex-direction: row-reverse;
}

.react-bootstrap-table-page-btns-ul>.page-item .page-link {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    margin-left: 5px;
    border-radius: 5px;
    color: #000;
    font-weight: 500;
    box-shadow: 1px 3px 12px #00000030;
}

.react-bootstrap-table-page-btns-ul>.page-item.active .page-link {
    background-color: #005d38;
    border-color: #005d38;
    color: #fff;
}

.success_heading {
    color: #005d38;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}

.change_plan_btn {
    background-color: #83B42B;
    border: 1px solid #83B42B;
    /* width: 30%;
    height: 40px; */
    font-size: clamp(0.75rem, 2.5vw, 1rem);
}

.cancel_plan_btn {
    background-color: rgba(199, 0, 0, 0.733);
    border: 1px solid rgba(197, 0, 0, 0.733);
    /* width: 30%;
    height: 40px; */
    font-size: clamp(0.75rem, 2.5vw, 1rem);
}

.cancel_plan_btn:hover,
.cancel_plan_btn:focus,
.cancel_plan_btn.active,
.cancel_plan_btn:active {
    background-color: rgba(168, 0, 0, 0.836);
    border: 1px solid rgba(173, 44, 44, 0.733);
}

.change_plan_btn:hover,
.change_plan_btn:focus,
.change_plan_btn.active,
.change_plan_btn:active {
    background-color: #005d38;
    border: 1px solid #005d38;
}

.sage__gold_tag_span {
    background: linear-gradient(90deg, #E3BA62 0%, #FBEE95 50%, #E3BA62 100%);
    padding: 5px 15px;
    margin-right: 5px;
    border-radius: 50%;
}

.sage__platinum_tag_span {
    background: linear-gradient(90deg, #9b9995 0%, #e2e2de 50%, #afafaf 100%);
    padding: 5px 15px;
    margin-right: 5px;
    border-radius: 50%;
}

/*Success checkmark styling*/
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #005d38;
    fill: #005d38;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 50%;
    height: 70%;
    border-radius: 50%;
    display: block;
    stroke-width: 3;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #005d38;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.personal_info_div {
    background-color: #005d38d2;
    display: flex;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    align-content: center;

}

.personal_info_div>span {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-family: "Roboto, Regular";
}

.form_labels {
    color: #005d38;
    font-size: 16px;
    font-weight: 500;
}

.myProfile_form_icon {
    top: 47px;
    left: 25px;
    position: absolute;
    color: #7A7A7A;
}

.myProfile_form_PhoneInput .form-control {
    border: 2px solid #005d38 !important;
    width: 100% !important;
    padding-left: 55px !important;
}

.myProfile_form_PhoneInput .flag-dropdown {
    border: 2px solid #005d38 !important;
    width: 50px !important;
}

.update_info_button {
    background-color: #83B42B;
    border: 1px solid #83B42B;
    width: 30%;
    margin-right: 20px;
    /* height: 50px; */
    font-size: clamp(12px, 2.5vw, 16px);
    align-self: flex-end;
}

.update_info_button:hover,
.update_info_button:focus,
.update_info_button.active,
.update_info_button:active {
    background-color: #005d38;
    border: 1px solid #005d38;
}

.my_plan_card {
    border-radius: 15px 15px 0px 0px !important;
    box-shadow: 1px 3px 12px #00000029;
}

.my_plan_card_header {
    background-color: #005d38d3 !important;
    border-radius: 15px 15px 0px 0px !important;
    text-align: center;
}

.my_plan_card_header>span {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    font-family: 'Roboto-Medium';
}

.my_subscibed_plan_div {
    margin: 10px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #5A5A5A;
    width: 90%;
    height: 190px;
    padding: 0px 0px;
    border-radius: 10px;
}

.my_subscibed_gold_plan {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.my_subscibed_gold_plan_Price {
    width: 100%;
    top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.my_subscibed_credit_Card_text {
    width: 100%;
    top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.sage_gold_plan_badge {
    padding: 25px 25px;
    margin-right: 10px;
    background: linear-gradient(90deg, #E3BA62 0%, #FBEE95 50%, #E3BA62 100%);
    border-radius: 50%;
}

.sage_platinum_plan_badge {
    padding: 25px 25px;
    margin-right: 10px;
    background: linear-gradient(90deg, #9b9995 0%, #e2e2de 50%, #afafaf 100%);
    border-radius: 50%;
}

.sage_gold_plan_title {
    padding: 0px;
    margin: 0px;
    color: #000;
    font-size: clamp(1.5rem, 3.5vw, 1.75rem);
    font-weight: 500;
}

.sage_gold_plan_price {
    padding: 0px;
    margin: 0px;
    color: #000;
    font-family: 'Times New Roman', Times, serif;
    font-size: clamp(1.75rem, 3.5vw, 2.25rem);
    font-weight: 500;
}

.my_subscibed_gold_plan_credit_card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.sage_creditCard_image {
    margin-right: 10px;
    border-radius: 50%;
    width: 15%;
}

.sage_credit_card_number {
    padding: 0px;
    margin: 0px;
    color: #000;
    font-size: clamp(1rem, 3.5vw, 1rem);
    font-weight: 500;
}

.sage_plan_ending_warningText {
    padding: 0px;
    margin: 0px;
    color: #000;
    font-family: 'Times New Roman', Times, serif;
    font-size: clamp(0.75rem, 2.5vw, 1.25rem);
    font-weight: 500;
}

.my_choosed_plan_benifits_div {
    position: relative;
    margin-top: 20px;
    background-color: #005d38d3;
    text-align: center;
    padding: 15px 0px;

}

.my_choosed_plan_benifits_div>span {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    font-family: 'Roboto-Medium';
}

.my_choosed_plan_benifits_points_div {
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff;
}

.my_choosed_plan_benifits_points_div>ul>li {
    list-style: none;
    font-size: clamp(0.25rem, 2.2vw, 1.75rem);
}

.my_choosed_plan_benifits_points_div>ul>li>.gold_check_icon {
    margin-right: 10px;
    color: #005d38;
    font-size: 30px;
}

.my_choosed_plan_benifits_points_div>ul>li>.gold_close_icon {
    margin-right: 10px;
    color: #FF4A4A;
    font-size: 30px;
}

.myPlan_main_card {
    border-radius: 15px;
    box-shadow: 1px 3px 12px #00000033;
}

.myPlan_sidebar_card {
    border-radius: 15px;
    box-shadow: 1px 3px 12px #00000033;
}

.myPlan_sidebar_Image_div {
    /* background-color: #005d38; */
    padding: 30px 10px 60px 10px;
}

.myPlan_sidebar_Image_div>img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
}

.rightSide_price_gold_div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 5px;
}

.pricing_side_card_inner_div {
    padding: 10px 10px;
}

.rightSide_price_gold_div:hover {
    background-color: #45BC8D;
    cursor: pointer;
}

.rightSide_quater_price_gold_div:hover {
    background-color: #2B79B4;
    cursor: pointer;
}

.rightSide_Annual_price_gold_div:hover {
    background-color: #FF994A;
    cursor: pointer;
}

.rightSide_price_gold_div:hover .pricing_side_card_heading_div {
    background-color: #fff;
}

.rightSide_price_gold_div:hover .pricing_side_card_heading_div>span {
    color: #45BC8D;
}

.rightSide_price_gold_div:hover .pricing_side_card_price_div>span {
    color: #fff;
}

.rightSide_price_gold_div:hover .pricing_popup_card_doted_div {
    border-top: 2px dashed #fff;
}

.rightSide_price_gold_div:hover .pricing_side_card_text_div>span {
    color: #fff;
}

.rightSide_quater_price_gold_div:hover .quater_pricing_side_card_heading_div {
    background-color: #fff;
}

.rightSide_quater_price_gold_div:hover .quater_pricing_side_card_heading_div>span {
    color: #2B79B4;
}

.rightSide_quater_price_gold_div:hover .quater_pricing_side_card_price_div>span {
    color: #fff;
}

.rightSide_quater_price_gold_div:hover .pricing_popup_card_doted_div {
    border-top: 2px dashed #fff;
}

.rightSide_quater_price_gold_div:hover .side_pricing_discount_span {
    color: #fff;
}

.rightSide_quater_price_gold_div:hover .quater_pricing_side_card_text_div>span {
    color: #fff;
}

.rightSide_Annual_price_gold_div:hover .Annual_pricing_side_card_heading_div {
    background-color: #fff;
}

.rightSide_Annual_price_gold_div:hover .Annual_pricing_side_card_heading_div>span {
    color: #FF994A;
}

.rightSide_Annual_price_gold_div:hover .Annual_pricing_side_card_price_div>span {
    color: #fff;
}

.rightSide_Annual_price_gold_div:hover .pricing_popup_card_doted_div {
    border-top: 2px dashed #fff;
}

.rightSide_Annual_price_gold_div:hover .side_pricing_discount_span {
    color: #fff;
}

.rightSide_Annual_price_gold_div:hover .Annual_pricing_side_card_text_div>span {
    color: #fff;
}

.rightSide_quater_price_gold_div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 5px;
}

.rightSide_Annual_price_gold_div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 5px;
}

.pricing_side_card_heading_div {
    background-color: #45BC8D;
    padding: 10px 0px;
    text-align: center;
    border-radius: 5px;
}

.quater_pricing_side_card_heading_div {
    background-color: #2B79B4;
    padding: 10px 0px;
    text-align: center;
    border-radius: 5px;
}

.Annual_pricing_side_card_heading_div {
    background-color: #FF994A;
    padding: 10px 0px;
    text-align: center;
    border-radius: 5px;
}

.pricing_side_card_heading_div>span {
    color: #fff;
}

.quater_pricing_side_card_heading_div>span {
    color: #fff;
}

.Annual_pricing_side_card_heading_div>span {
    color: #fff;
}

.pricing_side_card_price_div {
    padding: 5px 0px;
    text-align: center;
    border-radius: 3px;
    margin: 0px 0px;
}

.quater_pricing_side_card_price_div {
    padding: 5px 0px;
    text-align: center;
    border-radius: 3px;
    margin: 0px 0px;
}

.Annual_pricing_side_card_price_div {
    padding: 5px 0px;
    text-align: center;
    border-radius: 3px;
    margin: 0px 0px;
}

.pricing_side_card_price_div>span {
    color: #45BC8D;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Roboto,Medium';
}

.Annual_pricing_side_card_price_div>span {
    color: #FF994A;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Roboto, Medium';
}

.quater_pricing_side_card_price_div>span {
    color: #2B79B4;
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Roboto, Medium';
}


.pricing_side_card_text_div {
    text-align: justify;
}

.quater_pricing_side_card_text_div {
    text-align: justify;
}

.pricing_side_card_text_div>span {
    color: #000;
    font-size: 14px;
}

.Annual_pricing_side_card_text_div>span {
    color: #000;
    font-size: 14px;
}

.quater_pricing_side_card_text_div>span {
    color: #000;
    font-size: 14px;
}

.Annual_pricing_side_card_text_div>span {
    color: #000;
    font-size: 14px;
}

.side_pricing_discount_span {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    justify-content: flex-end;
    display: flex;
    margin-top: -15px;
}

.verify_button {
    background-color: #83B42B;
    border: 1px solid #83B42B;
    /* width: 20%; */
    width: min(20vw, 120px);
    position: absolute;
    right: 20px;
    top: 35px;
    height: min(13vw, 40px);
    font-size: clamp(12px, 1.3vw, 14px);
    align-self: flex-end;
}

.verify_button:hover,
.verify_button:focus,
.verify_button.active,
.verify_button:active {
    background-color: #005d38;
    border: 1px solid #005d38;
}

.changePlan_button_div {
    /* background-color: #005d38; */
    width: fit-content;
    position: absolute;
    top: 17px;
    right: min(70px, 10vw, 85px);
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
}

/*success checkmark styling end*/




/*======================
    404 page
=======================*/


.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}

.contant_box_404 {
    margin-top: 0px;
}