@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");
html {
  --primary-color: #a80000;
  --secondary-color: #750707;
  --primary-font: "Cairo-Regular";
}
body {
  overflow-x: hidden;
}
.error-message-generic p {
  color: var(--primary-color) !important;
  word-break: break-word;
  white-space: break-spaces;
}

.break-word {
  word-break: break-word;
}

.no-border {
  border: none !important;
}

.max-width-100 {
  max-width: 100% !important;
}

.no-data {
  color: var(--red-color);
  text-transform: uppercase;
  margin-bottom: 50px;
}

.no-data-button {
  padding: 15px 25px;
  background: var(--red-color);
  color: white !important;
  border-radius: 5px;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  border-bottom: 1px solid;
}

.landing-page-top-section {
  background-color: #f8f8f8;
  height: 55px;
  align-items: center;
  display: flex;
}

.landing-page-top-section .navbar-nav {
  flex-direction: unset;
}

.landing-page-top-section .navbar-nav li {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-size: 18px;
}
.landing-page-top-section .navbar-nav a {
  color: black;
}

.landing-page-top-section nav {
  padding: 0.5rem 0;
}

.landing-page-top-section svg {
  margin-right: 8px;
}

.landing-page-top-section {
  font-family: Cairo-Regular;
}

.landing-page-top-section .container-fluid,
.header-section .container-fluid,
.welcome-section .container-fluid,
.consult-info-section .container-fluid,
.speciality-section .container-fluid,
.articles-section .container-fluid,
.faq-section .container-fluid,
.app-section .container-fluid,
.partner-section .container-fluid,
.impact-section .container-fluid,
.footer .container-fluid {
  width: 96%;
}
.health-tools-section .container-fluid {
  width: 99%;
}
.header-section header img {
  width: 70%;
}

.header-section header ul li {
  list-style: none;
}

.header-section header ul {
  height: 100%;
  align-items: center;
  margin: 0;
}

.header-section header ul li p,
.header-section header ul h2 {
  margin: 0;
}

.header-section header ul li {
  padding-right: 100px;
}

.welcome-section {
  
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
  display: flex;
  align-items: center;
}

.welcome-text {
  background-color: #fcfdfdd9;
  height: 345px;
  padding: 65px 35.7px;
  border-radius: 20px 0 0 20px;
}

.welcome-section .container-fluid {
  width: 100% !important;
}

.welcome-section .welcome-text h2 {
  font-size: 50px;
  font-family: Cairo-Bold;
  line-height: 1;
  font-weight: 900;
  margin-bottom: 40px;
  width:94%;
  color: #43577c;
}
.btn-orange {
  color: #ffffff;
  cursor: pointer;
  height: 66px;
  width: 245px;
  border-radius: 0;
  border: none;
  font-size: 22px;
  font-family: "Cairo-Bold";
  background-color: #f26522;
}
.btn-green {
  color: #ffffff;
  cursor: pointer;
  height: 66px;
  width: 245px;
  border: none;
  font-size: 22px;
  border-radius: 0;
  font-family: "Cairo-Bold";
  background-color: #9ec54b;
}
.consult-info-section {
  padding: 40px 0 60px 0;
}
.consult-info-section header {
  align-items: center;
  font-size: 46px;
  text-align-last: center;
  font-family: "Cairo-Bold";
  color: black;
}
.consult-info-section .nav-tabs {
  border-bottom: 1px solid #ddd;
}
.consult-info-section .consult-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #f26522 !important;
  border: none;
  color: #f26522;
}
.consult-info-section .consult-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  border: none;
  color: #f26522;
  border-bottom: 2px solid #f26522 !important;
}

.consult-info-section .consult-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  font-size: 27px;
  font-family: "Cairo-Regular";
  padding: 10px 30px;
  color: black;
}
.consult-info-section h2 {
  color: #6c8bc6;
  font-size: 30px;
  font-family: Cairo-Regular;
}
.consult-info-section h1 {
  color: #616060;
  font-size: 40px;
  font-family: Cairo-Regular;
}
.consult-info-section p {
  font-size: 18px;
  color: #616060;
  line-height: 2;
}
.consult-info-section img {
  height: 300px;
}
.consult-section {
  padding: 40px 0 60px 0;
}
.speciality-section {
  padding: 40px 0 70px;
  background-color: #eff4fe;
}
.speciality-section header {
  align-items: center;
  font-size: 46px;
  text-align-last: center;
  font-family: "Cairo-Bold";
  color: black;
}

.speciality-section .slick-prev:before,
.speciality-section .slick-next:before {
  color: black;
  font-size: 50px;
}
.speciality-section .box {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.speciality-section .slide-item h5 {
  color: #000000;
  text-align: center;
  font-family: Cairo-Regular;
  font-size: 18px !important;
  width: 55%;
  margin: auto;
  margin-top: 10px;
}
.speciality-section .slick-dots li button:before {
  font-size: 25px;
  color: white;
  opacity: 1;
}
.speciality-section .slick-dots li.slick-active button:before {
  font-size: 25px;
  color: #8bb1f9 !important;
  opacity: 1;
}
.speciality-section .box img {
  width: 65px;
}
.speciality-section .slick-next {
  right: 27px;
  z-index: 1;
}
.speciality-section .slick-prev {
  left: -3px;
  z-index: 1;
}
.slider-red-color {
  background-color: #dc6a51;
}
.slider-yellow-color {
  background-color: #f8b24f;
}
.slider-blue-color {
  background-color: #7d9fc9;
}
.slider-pink-color {
  background-color: #d86884;
}
.slider-green-color {
  background-color: #88c594;
}
.articles-section {
  padding: 40px 0 55px 0;
}
.articles-section header {
  align-items: center;
  font-size: 46px;
  text-align-last: center;
  font-family: "Cairo-Bold";
  color: black;
}
.articles-section img {
  width: 95%;
}
.articles-section span {
  font-size: 36px;
  color: #6c8bc680;
  font-family: Cairo-Bold;
}
.articles-section p {
  padding-top: 3px;
  font-size: 30px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Cairo-Regular";
  line-height: 1.5;
}
.articles-section h6 {
  font-family: "Cairo-Regular";
  font-size: 16px !important;
  line-height: 1.4;
  color: black;
}
.articles-section .custom-padding {
  padding-left: 90px;
}
.articles-section .custom-padding img {
  width: 38px;
}
.articles-section .custom-padding {
  color: #009fe3;
  font-size: 18px;
  font-family: "Cairo-Regular";
}
.health-tools-section {
  background-color: #eff4fe;
  padding: 40px 0 70px;
}
.health-tools-section header {
  align-items: center;
  font-size: 46px;
  text-align-last: center;
  font-family: "Cairo-Bold";
  color: black;
}
.health-tools-section .box {
  margin: 0 20px;
  padding: 16px 0;
  border: 1px solid #adadad6b;
  text-align: center;
}
.health-tools-section img {
  margin: auto;
  width: 65%;
}

.health-tools-section h5 {
  margin-top: 10px;
  color: #6c8bc6;
  font-family: "Cairo-Regular";
  margin: auto;
  width: 60%;
  line-height: 1.5;
}
.health-tools-section .slick-dots li button:before {
  font-size: 25px;
  color: #8bb1f9;
  opacity: 0.8;
}
.health-tools-section .slick-dots li.slick-active button:before {
  font-size: 25px;
  color: #6c8bc6 !important;
  opacity: 1;
}
.health-tools-section .custom-width {
  width: 39%;
}
.faq-section {
  background-color: #f8f8f8 !important;
  padding: 40px 0 65px;
}
.faq-section header {
  align-items: center;
  font-size: 46px;
  text-align-last: center;
  font-family: "Cairo-Bold";
  color: black;
}
.faq-section .accordion > .card > .card-header {
  background-color: #eff4fe;
  font-size: 26px;
  font-family: "Cairo-Regular";
  padding: 20px;
  border: none;
  cursor: pointer;
}
.faq-section .accordion .card {
  border: none;
  margin: 25px 0px 0px 0px;
  box-shadow: 0px 2px 4px 0px #4b4a4a40;
}

.faq-section .accordion .card-body {
  background-color: #eff4fe;
  font-size: 18px;
  font-family: "Cairo-Regular";
  padding-top: 5px;
}
.app-section {
  background-color: #eff4fe !important;
  padding: 60px 0;
}
.app-section img {
  width: 350px;
}
.app-section h2 {
  color: #6c8bc6;
  font-family: "Cairo-Bold";
  font-size: 35px;
}

.app-section p {
  padding-right: 180px;
  font-family: "Cairo-Regular";
  font-size: 19px;
  color: black;
}
.app-section button {
  color: white;
  background-color: #2d2d32;
  border: none;
  padding: 6px 0 12px 0;
  font-size: 26px;
  width: 250px;
  border-radius: 10px;
  margin-right: 22px;
}
.partner-section {
  padding: 40px 0 75px !important;
}
.partner-section header {
  align-items: center;
  font-size: 46px;
  text-align-last: center;
  font-family: "Cairo-Bold";
  color: black;
  margin-bottom: 10px;
}
.partner-section h6 {
  align-items: center;
  font-size: 30px;
  text-align-last: center;
  font-family: Cairo-Regular;
  color: black;
}
.partner-section .box {
  height: 150px;
  display: flex;
}
.partner-section img {
  width: 140px;
  margin: auto;
}
.partner-section .slick-dots li button:before {
  font-size: 25px;
  color: #6c8bc6 !important;
  opacity: 0.5;
}
.partner-section .slick-dots li.slick-active button:before {
  font-size: 25px;
  color: #6c8bc6 !important;
  opacity: 1;
}
.impact-section {
  padding: 40px 0 70px !important;
  background-color: #eff4fe;
}
.impact-section header {
  align-items: center;
  font-size: 46px;
  text-align-last: center;
  font-family: "Cairo-Bold";
  color: black;
}
.impact-section .image-div {
  height: 85px;
  display: flex;
}
.impact-section img {
  margin: auto;
  width: 70px;
}
.impact-section h4 {
  color: black;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
  font-family: Cairo-Bold;
}

.impact-section p {
  width: 40%;
  margin: auto;
  line-height: 2;
  font-style: normal;
  font-weight: normal;
  font-size: 21px !important;
  color: #000000;
  text-align: center;
  font-family: Cairo-Regular;
}
.impact-section .slick-dots li button:before {
  font-size: 25px;
  color: #6c8bc6 !important;
  opacity: 0.5;
}
.impact-section .slick-dots li.slick-active button:before {
  font-size: 25px;
  color: #6c8bc6 !important;
  opacity: 1;
}
.footer-logo-img {
  width: 18%;
}
.footer-bottom .copyright ul li {
  float: left;
  padding-right: 5px;
}
.footer-bottom .copyright ul {
  list-style: none;
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
}
.footer-bottom .copyright ul li img {
  width: 40px;
}
.pat-dashboard-welcome-section {
  height: 330vh;
  background-color: white;
}
.pat-dashboard-welcome-section .dashboard-wall {
 
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
}
.pat-dashboard-welcome-section .dashboard-wall .container-fluid {
  width: 92.5%;
  padding: 0;
}
.pat-dashboard-welcome-section .color-overlay {
  background-color: rgba(100, 149, 237, 0.13);
  height: inherit;
}
.pat-dashboard-welcome-section .dashboard-wall .welcome-user {
  font-family: "Cairo";
  font-weight: 800;
  font-size: 60px;
  height: 100%;
  transform: translateY(35%);
  color: white;
  text-shadow: 1px 1px 1px rgb(150 150 150 / 62%);
}

.pat-dashboard-welcome-section .dashboard-wall ul {
  background: #e3e9f4;
  list-style: none;
  padding: 10px 0 30px 0;
}
.pat-dashboard-welcome-section .dashboard-wall ul li {
  list-style: none;
  padding: 20px 20px 0 20px;
}
.pat-dashboard-welcome-section .dashboard-wall ul li div {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.pat-dashboard-welcome-section .dashboard-wall ul li div img {
  height: 40px;
}
.pat-dashboard-welcome-section .dashboard-wall ul li p {
  font-family: "Cairo";
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-top: 5px !important;
  margin: 0px;
}
.pat-dashboard-welcome-section .orange-color {
  color: #f26522;
}
.pat-dashboard-welcome-section .bg-custom-blue {
  background-color: #009fe3;
}
.pat-dashboard-welcome-section .custom-blue-color {
  color: #009fe3;
}
.pat-dashboard-welcome-section .bg-green {
  background-color: #9ec54b;
}
.pat-dashboard-welcome-section .green-color {
  color: #9ec54b;
}
.pat-dashboard-welcome-section .bg-orange {
  background-color: #f26522;
}
.pat-dashboard-welcome-section .dashboard-wall .first-Section {
  height: 442px;
}
.pat-dashboard-welcome-section .dashboard-wall .Latest-Deals-Section {
  margin-top: 32px;
  padding: 25px 20px;
  background: #ffffffd1;
}
.Latest-Deals-Section h3 {
  font-family: "Cairo-Bold";
  color: #009fe3;
}
.Latest-Deals-Section span {
  border-radius: 50%;
  height: 30px;
  width: 33px;
  color: white;
  background-color: white;
  position: absolute;
  right: 30px;
  top: 30px;
}
.Latest-Deals-Section h4 {
  font-family: "Cairo";
  color: white;
  font-weight: 600;
  width: 155px;
  font-size: 22px;
}
.main-content-section {
  padding: 0 20px;
}
.main-content-section .card h3 {
  padding: 20px 24px;
  vertical-align: middle;
  line-height: 24px;
  display: inline-block;
  font-family: "Cairo";
  font-weight: 700;
  font-size: 26px;
  color: #009fe3;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
}

.main-content-section .card h5 {
  display: inline-block;
  font-family: "Cairo-Bold";
  color: #009fe3;
  font-size: 20px;
  font-weight: 500;
}
.main-content-section .card {
  background-color: #f8f8f8 !important;
  /* box-shadow: 0 0 10px lightgrey; */
}
.main-content-section p {
  font-size: 15px;
  font-family: "Cairo";
  color: #989898;
}
.main-content-section .next-appointment .card h3 {
  padding: 20px 24px;
  font-weight: 500;
  color: black;
  vertical-align: middle;
  line-height: 24px;
  font-size: 20px;
  display: inline-block;
  font-family: "Cairo";
  font-weight: 700;
  font-size: 28px;
  color: #009fe3;
  font-family: "Cairo";
  font-weight: 700;
  font-size: 28px;
  color: #009fe3;
  border-bottom: none;
  width: 100%;
  margin: 0;
}
.main-content-section .card-body {
  padding: 15px 25px 5px 25px;
}
.main-content-section .next-appointment .card-body {
  padding: 0;
}
.main-content-section .next-appointment .card-body .table th {
  border: none;
  padding-left: 25px;
  color: #009fe3;
}
.main-content-section .next-appointment .card-body .table td {
  padding-left: 25px;
}
.main-content-section .patient-plan .card-body h4 {
  font-family: "Cairo";
  color: #989898;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  line-height: 1;
}
.main-content-section .patient-plan .card-body h6 {
  font-family: "Cairo";
  color: #009fe3 !important;
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 40px;
}
.main-content-section .patient-plan .card-body button {
  padding: 10px 0px;
  color: white;
  font-family: "Cairo";
  font-weight: 600;
  font-size: 16px;
  border-radius: 0;
  margin: 0px 20px;
  margin-right: 20px;
  width: 40%;
}
.main-content-section .patient-plan .card-body {
  padding: 15px 25px 25px 25px;
}
.main-content-section .next-appointment .card-body {
  max-height: 312px;
  min-height: 312px;
  overflow-y: auto;
  overflow-x: hidden;
}
.main-content-section .patient-medication .card-body {
  max-height: 220px;
  min-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-content-section .card-body::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.main-content-section .card-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.main-content-section .card-body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.main-content-section .card-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* PharmacyDashBoard - Zeeshan */

.pharmacy-welcome-section{
  padding: 33px 0;
  height: 150px;
  background: #F2FBFF;
}
.pharmacy-welcome-text{
  font-family: Cairo;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1.5;
  letter-spacing: 0em;
  color: #000000;
  padding-left: 15px !important;
}
.pharmacy-welcome-section ul{
  display: inline-flex;
}
.pharmacy-welcome-section ul li{
  font-family: Cairo;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  color: #000000;
  padding-right: 50px!important;
}
.pharmacy-welcome-section ul li::marker{
  font-size: 16px;;
  color:#009FE3;
}
.parent-pharmacy-section{
  background-color: #fff;
}
.pharmacy-new-med{
  font-family: Cairo;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  }
.pharmacy-med-card-1{
  background: #6C8BC6;
  height: 150px;
}
.pharmacy-med-card-2{
  background: #03C8B2;
  height: 150px;
}
.pharmacy-med-card-3{
  background: #FFA500;
  height: 150px;
}
.pharmacy-med-card-4{
  background: #45DA92;
  height: 150px;
}
.pharmacy-med-card-5{
  background: #009FE3;
  height: 150px;
}
.pharmacy-med-card-6{
  background: #FF5963;
  height: 150px;
}
.pharmacy-med-card-7{
  background: #2EABE0;
  height: 150px;
}
.pharmacy-med-card-8{
  background: #858585;
  height: 150px;
}
.pharmacy-widget-info{
  display: flex;
  padding-top: 20px;
}
.pharmacy-card-icon{
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  display: block;
  text-align: center;
}
.pharmacy-card-file-icon{
  font-size: 40px;
  padding: 9px;
}
.pharmacy-med-card-1 .pharmacy-card-file-icon{
  color: #6C8BC6;
}
.pharmacy-med-card-2 .pharmacy-card-file-icon{
  color: #03C8B2;
}
.pharmacy-med-card-3 .pharmacy-card-file-icon{
  color: #FFA500;
}
.pharmacy-med-card-4 .pharmacy-card-file-icon{
  color: #45DA92;
}
.pharmacy-med-card-5 .pharmacy-card-file-icon{
  color: #009FE3;
}
.pharmacy-med-card-6 .pharmacy-card-file-icon{
  color: #FF5963;
}
.pharmacy-med-card-7 .pharmacy-card-file-icon{
  color: #2EABE0;
}
.pharmacy-med-card-8 .pharmacy-card-file-icon{
  color: #858585;
}
.pharmacy-card-count-text{
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  line-height: 0.9;
  margin-left: auto;
}
.pharmacy-card-count-text-underline{
  width: 70%;
  height: 1.5px;
  background-color: #fff;
  display: block;
  margin: 0 auto;
}
.bg-table-head{
  background: #009FE3;
  color: #fff;
}
.bg-table-head th{
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left !important;
}
/* .pharmacy-table-td td{
  width: 200px;
  padding: 15px ​40px;
  word-break: break-word;
} */
.pharmacy-table-td td h6{
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}
.medicine-total-cost{
  font-family: Cairo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center !important;

}
.btn-bg-primary{
  background: #009FE3;
  padding: 5px 8px;
  width: 135px;
  height: 42px;
  color: #fff;
  font-family: Cairo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 0px;
}
/* End PharmacyDashBoard - Zeeshan */
/* Talha Css for AskADoctorView */
/* .askadoctor-section{
	padding-top: 15px;
} */

.askadoctor-section-hero{
	height: auto;
	background-color: white;
  margin-bottom: 200px;
}
.askadoctor-section-alphalogo{
	height:80px;
}
.askadoctor-section-bgi{
	height: 680px;
background-image: url(../../img.png);
background-size: 100% 100%;
}
.askadoctor-section-bgi-child{
	height: auto;
  width: 520px;
	margin-top: 115px;
	background-color: white;
  border: 1px solid  #FFFFFF;
  box-shadow: 0px 4px 4px 0px #00000040;
  ;
  /* background-color: #43577c; */
}
.askadoctor-section-bgi-child h2{
	font-family:Cairo-bold;
	color: #43577C;
	font-size: 30px;
	font-weight: 700;
}
.askadoctor-section-bgi-child p{
	font-size: 17px;
	font-family:cairo;
	font-weight: 600;
  color: #000000;
}
.askadoctor-section-bgi-child-textarea{
  background-color:#F6F4F4;
  font-family:cairo;
  color:#968E8E ;
  border-radius: 7px;
  border: none;
  resize: none;
  
  }
  .askadoctor-section-bgi-child-div-text{
    color: #968E8E;
    line-height: 25.3px;
  }
  .askadoctor-section-dropdown{
    
  background-color: #F6F4F4 !important;
  color: #968E8E !important;
  border: 2px solid #F6F4F4 !important ;
  /* margin-top: -100px;. */
}
.askadoctor-section .dropdown{
  margin-top: -7px;
}
.askadoctor-section-dropdown:active{
  background-color: #F6F4F4 !important;
  color: #968E8E !important;
  border: 2px solid #F6F4F4 !important ;
}
.askadoctor-section-button{
  background-color: #E46520 !important;
  width: 85%;
  font-family: cairo;
  font-size: 22px;
  font-weight: 400;
  line-height: 34.5px;
  border: 1px solid #E46520 !important;
}
.askadoctor-section .dropdown-toggle::after{
  margin-left: 100px;
  /* margin-top: 100px; */
  font-size: 20px;
  color: #968E8E ;
}
.askadoctor-section-card{
  height: 700px;
  width: 100%;
  background-color: #E5E5E5;
}
.askadoctor-section-cardbody{
  height: 100px;
  background-color: white;
  border-radius: 5px;
}
.askadoctor-section-cardbody-1{
  height: auto;
  background-color: #C476B0;
  border-radius: 10px;
  padding-top: 17px;
}
.askadoctor-section-cardbody-1 p{
  height: 20px;
  width: 70px;
  text-align: center;
  background-color: white;
  /* line-height: 37px; */
  color: #000000;
  
}
.askadoctor-section-cardbody-1 h3{
  font-size: 20px;
  font-family: Cairo;
  color: white;
  font-weight: 700;
}
.askadoctor-section-cardbody-1 button{
  height: 40px;
  width: 150px;
  border-radius: 10px;
  background-color: white;
  border: none;
}
.askadoctor-section-cardbody-1-img{
  height: 120px;
  width: 120px;
  background-color: #E3E3E3;
  border-radius: 60px;
}
.askadoctor-section-doctor-img{
  height: 50px;
  width: 50px;
  border-radius: 40px;
}
.askadoctor-section-cardbody label{
  font-size: 22px;
  font-weight: 600;
  font-family: cairo;
  color: #000000;
}

/* ################### */
