/*
Author       : Dreamguys
Template Name: Doccure - Bootstrap Template
Version      : 1.3
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Bootstrap Classes

10. Focus Label
11. Header
12. Mobile Menu
13. Footer
14. Login
15. Home
16. Search
17. Doctor Profile
18. Booking
19. Checkout
20. Booking Success
21. Invoice View
22. Schedule Timings
23. Doctor Dashboard
24. Patient Profile
25. Add Billing
26. Chat
27. Doctor Profile Settings
28. Calendar
29. Patient Dashboard
30. Profile Settings
31. Appoitment List
32. Reviews
33. Voice call
34. Video Call
35. Outgoing Call
36. Incoming Call
37. Terms and Conditions
38. Blog
39. Blog Details
40. Blog Grid
41. Map List & Grid
42. Loader
43. Product
44. Cart
45. Slider
46. Responsive

========================================*/

/*-----------------
	1. General
-----------------------*/

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,900");

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

html {
  height: 100%;
}

body {
  background-color: #fff !important;
  color: #272b41;
  font-family: "Poppins", sans-serif;
  font-size: 0.9375rem;
  height: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #272b41;
  font-weight: 500;
}

.h1,
h1 {
  font-size: 2.25rem;
}

.h2,
h2 {
  font-size: 1.875rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.125rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

a {
  color: #2e3842;
}

a:hover {
  color: #09dca4;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  --webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input,
button,
a {
  transition: all 0.4s ease !important;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

button:focus {
  outline: 0;
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn.focus,
.btn:focus {
  box-shadow: inherit;
}

.form-control {
  /* border-color:   ; */
  border: 0.13rem solid #015d38;
  color: #333;
  font-size: 15px;
  min-height: 46px;
  padding: 6px 30px;
  border-radius: 0.3rem;
}

.table .form-control {
  font-size: 14px;
  min-height: 38px;
}

/* .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
} */
.form-control::-webkit-input-placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control::-moz-placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control::-ms-input-placeholder {
  color: #858585;
  font-size: 14px;
}

.form-control::placeholder {
  color: #858585;
  font-size: 14px;
}

.list-group-item {
  border: 1px solid #f0f0f0;
}

.content {
  min-height: 200px;
  padding: 30px 0 0;
}

/*-----------------
	3. Helper Class
-----------------------*/
#toggle_bttn {
  align-items: center;
  color: #333;
  display: inline-flex;
  float: left;
  font-size: 30px;
  height: 60px;
  justify-content: center;
  margin-left: 15px;
  padding: 0 15px;
}

.menu-dropdown a i {
  margin-right: 10px;
  font-size: 22px;
}

.mini-sidebar .menu {
  width: 66px !important;
}

.mini-sidebar .menu:hover {
  width: 240px !important;
}

/* .menu-dropdown li:hover{background:#000;} */
.p-20 {
  padding: 20px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.block {
  display: block !important;
}

.cal-icon {
  position: relative;
  width: 100%;
}

.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "FontAwesome";
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px;
}

/*-----------------
	2. Table
-----------------------*/
/* 
.table {
	color: #272b41;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #272b41;
}
table.table td h2 a:hover {
	color: #09dca4;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead {
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.table thead tr th {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr:last-child {
	border-color: transparent;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
} */

/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus,
.btn:focus {
  box-shadow: unset;
}

.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #272b41;
}

.btn.btn-rounded {
  border-radius: 50px;
}

/* .bg-primary,
.badge-primary {
  background-color: #09e5ab !important;
} */
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #09dca4 !important;
}

.bg-success,
.badge-success {
  background-color: #00e65b !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #00cc52 !important;
}

.bg-info,
.badge-info {
  background-color: #009efb !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #028ee1 !important;
}

.bg-warning,
.badge-warning {
  background-color: #ffbc34 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #e9ab2e !important;
}

.bg-danger,
.badge-danger {
  background-color: #d7403f !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #e63333 !important;
}

.bg-white {
  background-color: #fff;
}

.bg-purple,
.badge-purple {
  background-color: #9368e9 !important;
}

.text-primary,
.dropdown-menu>li>a.text-primary {
  color: #09e5ab !important;
}

.text-success,
.dropdown-menu>li>a.text-success {
  color: #00cc52 !important;
}

.text-danger,
.dropdown-menu>li>a.text-danger {
  color: #ff0100 !important;
}

.text-info,
.dropdown-menu>li>a.text-info {
  color: #009efb !important;
}

.text-warning,
.dropdown-menu>li>a.text-warning {
  color: #ffbc34 !important;
}

.text-purple,
.dropdown-menu>li>a.text-purple {
  color: #7460ee !important;
}

.text-muted {
  color: #757575 !important;
}

/* .btn-primary {
  background-color: #09e5ab;
  border: 1px solid #09e5ab;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #09dca4;
  border: 1px solid #09dca4;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #09dca4;
  border: 1px solid #09dca4;
} */
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle {
  background-color: #83b42b;
  border: 1px solid #83b42b;
  color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.active {
  background-color: #83b42b;
  border: 1px solid #83b42b;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #09e5ab;
  border-color: #09e5ab;
  color: #fff;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success {
  background-color: #83b42b;
  border: 1px solid #83b42b;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
  background-color: #005d38;
  border: 1px solid #005d38;
  color: #fff;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
  background-color: #00cc52;
  border: 1px solid #00cc52;
}

.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show>.btn-success.dropdown-toggle {
  background-color: #00cc52;
  border-color: #00cc52;
  color: #fff;
}

.btn-success.active:focus:not(:disabled):not(.disabled),
.btn-success:active:focus:not(:disabled):not(.disabled),
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #00e65b;
  border-color: #00e65b;
  color: #fff;
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb;
}

.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1;
}

.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show>.btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #fff;
}

.btn-info.active:focus:not(:disabled):not(.disabled),
.btn-info:active:focus:not(:disabled):not(.disabled),
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff;
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e;
}

.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show>.btn-warning.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #fff;
}

.btn-warning.active:focus:not(:disabled):not(.disabled),
.btn-warning:active:focus:not(:disabled):not(.disabled),
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff;
}

.btn-danger {
  background-color: #ff0100;
  border: 1px solid #ff0100;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
  background-color: #e63333;
  border: 1px solid #e63333;
}

.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle {
  background-color: #e63333;
  border-color: #e63333;
  color: #fff;
}

.btn-danger.active:focus:not(:disabled):not(.disabled),
.btn-danger:active:focus:not(:disabled):not(.disabled),
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff;
}

.btn-light.active:focus:not(:disabled):not(.disabled),
.btn-light:active:focus:not(:disabled):not(.disabled),
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-dark.active:focus:not(:disabled):not(.disabled),
.btn-dark:active:focus:not(:disabled):not(.disabled),
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-outline-primary {
  color: #09e5ab;
  border-color: #09e5ab;
}

.btn-outline-primary:hover {
  background-color: #09e5ab;
  border-color: #09e5ab;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #09e5ab;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: #09e5ab;
  border-color: #09e5ab;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:hover {
  background-color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00e65b;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  background-color: #00e65b;
  border-color: #00e65b;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  background-color: #009efb;
  border-color: #009efb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #ff0100;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  background-color: #ff0100;
  border-color: #ff0100;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #ababab;
}

.add-btn {
  max-width: 140px;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background-color: #20c0f3;
  border-color: #20c0f3;
}

.pagination>li>a,
.pagination>li>span {
  color: #20c0f3;
}

.page-link:hover {
  color: #20c0f3;
}

.page-link:focus {
  box-shadow: unset;
}

.page-item.active .page-link {
  background-color: #20c0f3;
  border-color: #20c0f3;
}

.dropdown-menu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-shadow: inherit;
  font-size: 14px;
  transform-origin: left top 0;
  width: -webkit-fill-available;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #0de0fe;
}

.navbar-nav .open .dropdown-menu {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.card {
  border: 1px solid #f0f0f0;
  margin-bottom: 1.875rem;
}

/* .card-body {
  padding: 1.5rem;
} */
.card-title {
  margin-bottom: 15px;
}

.card-header {
  border-bottom: 1px solid #f0f0f0;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

.card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.card .card-header .card-title {
  margin-bottom: 0;
}

.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #20c0f3;
  text-shadow: unset;
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: #20c0f3;
}

.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}

.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}

.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}

.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.form-control:focus {
  /* border-color: #bbb; */
  box-shadow: none;
  outline: 0 none;
  border-color: #015d38 !important;
}

/* .form-group {
  margin-bottom: 1.25rem;
} */
.slider-btn {
  background: #09e5ab;
  color: #fff;
  border: 1px solid #09e5ab;
  padding: 8px 16px !important;
  transition: 0.5s;
}

.slider-btn:hover {
  background: #fff;
  color: #09e5ab;
  border: 1px solid #fff;
  padding: 8px 16px !important;
}

.slider-btn-1 {
  color: #fff;
  border: 1px solid #0d2b77;
  background: #2e3842;
  padding: 8px 16px !important;
  transition: 0.5s;
}

.slider-btn-1:hover {
  color: #2e3842;
  border: 1px solid #fff;
  background: #fff;
  padding: 8px 16px !important;
}

/*-----------------
	10. Focus Label
-----------------------*/

.form-focus {
  height: 50px;
  position: relative;
}

.form-focus .focus-label {
  font-size: 14px;
  font-weight: 400;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
  -o-transform: translate3d(0, 22px, 0) scale(1);
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -8px;
  z-index: 1;
  color: #b8b8b8;
  margin-bottom: 0;
}

.form-focus.focused .focus-label {
  opacity: 1;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .form-control:focus~.focus-label,
.form-focus .form-control:-webkit-autofill~.focus-label {
  opacity: 1;
  font-weight: 400;
  top: -18px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .form-control {
  height: 50px;
  padding: 21px 12px 6px;
}

.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}

.form-focus .form-control:focus::-webkit-input-placeholder {
  transition: none;
}

.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}

.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 12px;
  z-index: 1;
}

.form-focus .select2-container .select2-selection--single {
  border: 1px solid #e3e3e3;
  height: 50px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 48px;
  right: 7px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}

.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}

.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #676767;
  font-size: 14px;
  font-weight: normal;
  line-height: 38px;
}

.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #fc6075;
}

/*-----------------
	7. Components
-----------------------*/

.section-header {
  margin-bottom: 1.875rem;
}

.section-header .section-title {
  color: #333;
}

.line {
  background-color: #00d0f1;
  height: 2px;
  margin: 0;
  width: 60px;
}

.comp-buttons .btn {
  margin-bottom: 5px;
}

.pagination-box .pagination {
  margin-top: 0;
}

.comp-dropdowns .btn-group {
  margin-bottom: 5px;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

.progress.progress-sm {
  height: 6px;
}

.progress.progress-md {
  height: 8px;
}

.progress.progress-lg {
  height: 18px;
}

.row.row-sm {
  margin-left: -3px;
  margin-right: -3px;
}

.row.row-sm>div {
  padding-left: 3px;
  padding-right: 3px;
}

.table-responsive {
  white-space: nowrap;
}

/* Avatar */

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

.avatar>img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.sorting_1 p {
  margin-bottom: 0px;
}

.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #00d0f1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
}

.avatar-online:before {
  background-color: #7bb13c;
}

.avatar-offline:before {
  background-color: #e84646;
}

.avatar-away:before {
  background-color: #ffbc34;
}

.avatar .border {
  border-width: 3px !important;
}

.avatar .rounded {
  border-radius: 6px !important;
}

.avatar .avatar-title {
  font-size: 18px;
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.avatar-xs .border {
  border-width: 2px !important;
}

.avatar-xs .rounded {
  border-radius: 4px !important;
}

.avatar-xs .avatar-title {
  font-size: 12px;
}

.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
  border-width: 1px;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-sm .border {
  border-width: 3px !important;
}

.avatar-sm .rounded {
  border-radius: 4px !important;
}

.avatar-sm .avatar-title {
  font-size: 15px;
}

.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
  border-width: 2px;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}

.avatar-lg .border {
  border-width: 3px !important;
}

.avatar-lg .rounded {
  border-radius: 8px !important;
}

.avatar-lg .avatar-title {
  font-size: 24px;
}

.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
  border-width: 3px;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xl .border {
  border-width: 4px !important;
}

.avatar-xl .rounded {
  border-radius: 8px !important;
}

.avatar-xl .avatar-title {
  font-size: 28px;
}

.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
  border-width: 4px;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

.avatar-xxl .border {
  border-width: 6px !important;
}

.avatar-xxl .rounded {
  border-radius: 8px !important;
}

.avatar-xxl .avatar-title {
  font-size: 30px;
}

.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
  border-width: 4px;
}

.avatar-group {
  display: inline-flex;
}

.avatar-group .avatar+.avatar {
  margin-left: -0.75rem;
}

.avatar-group .avatar-xs+.avatar-xs {
  margin-left: -0.40625rem;
}

.avatar-group .avatar-sm+.avatar-sm {
  margin-left: -0.625rem;
}

.avatar-group .avatar-lg+.avatar-lg {
  margin-left: -1rem;
}

.avatar-group .avatar-xl+.avatar-xl {
  margin-left: -1.28125rem;
}

.avatar-group .avatar:hover {
  z-index: 1;
}

/*-----------------
	11. Header
-----------------------*/
.header {
  /* background: #fff; */
  /* border-bottom: 1px solid #f0f0f0; */
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1002;
  height: 60px;
}

.header .header-left {
  float: left;
  height: 60px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  width: 240px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  display: none;
}

.header .header-left .logo {
  display: inline-block;
  line-height: 60px;
}

.header .header-left .logo img {
  max-height: 40px;
  width: auto;
}

.header-left .logo.logo-small {
  display: none;
}

.header .dropdown-menu>li>a {
  position: relative;
}

.header .dropdown-toggle:after {
  display: none;
}

.fe-bell {
  color: #f5f5f5;
}

.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-top: 16px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #015d38;
  color: whitesmoke;
  padding-left: 40px;
}

.header_message_box {
  position: relative;
  margin-top: 15px;
}

.user-menu.nav>li>a {
  color: #333;
  font-size: 14px;
  line-height: 58px;
  padding: 0 15px;
  height: 60px;
}

.user-menu.nav>li>a:hover,
.user-menu.nav>li>a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.user-menu.nav>li>a:hover i,
.user-menu.nav>li>a:focus i {
  color: #333;
}

.user-dropdown button,
.user-dropdown button:active,
.user-dropdown button:hover {
  background-color: inherit !important;
  border: none !important;
}

.user-img {
  display: inline-block;
  margin-right: 3px;
  position: relative;
  margin-top: 8px;
}

.user-img img {
  height: 31px;
  object-fit: cover;
  width: 45px;
}

.no-padding {
  padding: 0px !important;
}

.user-menu.nav>li>a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.user-header {
  width: 100%;
}

.user-menu .dropdown-menu {
  min-width: fit-content;
  padding: 0;
  position: absolute;
  /* inset: 0px auto auto 0px !important; */
  margin: 0px;
  /* transform: translate(-384.8px, 52px)!important; */
}

.notify .dropdown-toggle {
  margin-top: 5px;
}

.user-menu .dropdown-menu .dropdown-item {
  padding: 7px 15px;
}

.user-menu .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}

.user-menu .dropdown-menu .dropdown-item:hover {
  color: #666;
  background: #f5f5f5;
}

.submenu li a:hover {
  background: inherit !important;
  color: #0ce0ff !important;
}

.menu-hasdropdown:hover .submenu li {
  background: inherit !important;
}

.notification-time {
  color: #666;
}

.header .dropdown-menu>li>a:focus,
.header .dropdown-menu>li>a:hover {
  background-color: #00d0f1;
  color: #fff;
}

.header .dropdown-menu>li>a:focus i,
.header .dropdown-menu>li>a:hover i {
  color: #fff;
}

.header .dropdown-menu>li>a {
  padding: 10px 18px;
}

.header .dropdown-menu>li>a i {
  color: #00d0f1;
  margin-right: 10px;
  text-align: center;
  width: 18px;
}

.header .user-menu .dropdown-menu>li>a i {
  color: #00d0f1;
  font-size: 16px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center;
}

.header .user-menu .dropdown-menu>li>a:focus i,
.header .user-menu .dropdown-menu>li>a:hover i {
  color: #fff;
}

.mobile_btn {
  display: none;
  float: left;
}

.slide-nav .sidebar {
  margin-left: 0;
}

.app-dropdown .dropdown-menu {
  padding: 0;
  width: 300px;
}

.app-dropdown-menu .app-list {
  padding: 15px;
}

.app-dropdown-menu .app-item {
  border: 1px solid transparent;
  border-radius: 3px;
  color: #737373;
  display: block;
  padding: 10px 0;
  text-align: center;
}

.app-dropdown-menu .app-item i {
  font-size: 20px;
  height: 24px;
}

.app-dropdown-menu .app-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-dropdown-menu .app-item:hover,
.app-dropdown-menu .app-item:focus,
.app-dropdown-menu .app-item:active,
.app-dropdown-menu .app-item.active {
  background-color: #f9f9f9;
  border-color: #e3e3e3;
}

.app-list>div+div {
  margin-top: 5px;
}

.app-list>.row {
  margin-left: -5px;
  margin-right: -5px;
}

.app-list>.row>.col {
  padding-left: 5px;
  padding-right: 5px;
}

.user-header {
  background-color: #f9f9f9;
  display: flex;
  padding: 10px 15px;
}

.user-header .user-text {
  margin-left: 10px;
}

.user-header .user-text h6 {
  margin-bottom: 2px;
}

.menu-title {
  color: #a3a3a3;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0 25px;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1000;
}

html.menu-opened {
  overflow: hidden;
}

html.menu-opened body {
  overflow: hidden;
}

/* Search */

.top-nav-search {
  float: left;
  margin-left: 15px;
}

.top-nav-search form {
  margin-top: 10px;
  position: relative;
  width: 230px;
}

.top-nav-search .form-control {
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  color: #333;
  height: 40px;
  padding: 10px 50px 10px 15px;
}

.top-nav-search .btn {
  background-color: transparent;
  border-color: transparent;
  color: #333;
  min-height: 40px;
  padding: 7px 15px;
  position: absolute;
  right: 0;
  top: 0;
}

.top-nav-search .form-control::-webkit-input-placeholder {
  color: #333;
}

.top-nav-search .form-control::-moz-placeholder {
  color: #333;
}

.top-nav-search .form-control:-ms-input-placeholder {
  color: #333;
}

.top-nav-search .form-control::-ms-input-placeholder {
  color: #333;
}

.top-nav-search .form-control::placeholder {
  color: #333;
}

.top-nav-search.active form {
  display: block;
  left: 0;
  position: absolute;
}

/*-----------------
	9. Sidebar
-----------------------*/

.sidebar {
  background-color: #015d38;
  border-top-right-radius: 30px;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 0px;
  transition: all 0.2s ease-in-out 0s;
  width: 143px;
  z-index: 1001;
}

.sidebar.opened {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu {
  padding: 15px;
  padding-right: 0px;
}

.sidebar-menu ul {
  margin-left: -12px !important;
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.sidebar-menu li a {
  color: #fff;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px;
}

/* .sidebar-menu li a:hover {
    color: #5ae8ff;
} */
/* .sidebar-menu > ul > li > a:hover {
	background-color: #19c1dc; 
    color: #015d38;
    background-color:#fff;
} */
.sidebar-menu>ul>li.active>a:hover {
  color: #015d38;
  /* background-color:#fff; */
}

.sidebar-menu li.active a {
  color: #015d38;
}

.dashboard_image {
  margin-top: 40px;
  /* padding-bottom: 70px; */
}

.dashboard_image img {
  width: 70px;
  margin-left: 21px;
  transition: width 0.8s;
}

.menu-title {
  color: #ebebeb;
  display: flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 15px;
  white-space: nowrap;
}

.menu-title>i {
  float: right;
  line-height: 40px;
}

.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0;
}

.sidebar-menu>ul>li.active {
  background-image: url(../images/sidebarbg.png);
  background-repeat: no-repeat;
  background-position: top 0px right 35px;
  width: 175px;
  height: 85px;
  background-size: 123px;
  margin-bottom: -30px;
  transition: width height background-size 0.6s ease-in-out;
}

.mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li.active {
  background-image: url(../images/sidebarbg.png);
  background-repeat: no-repeat;
  background-position: top 0px right 35px;
  width: 175px;
  height: 85px;
  background-size: 123px;
  margin-bottom: -30px;
  transition: all 0.6s;
}

.mini-sidebar .sidebar .sidebar-menu>ul>li.active {
  background-image: url(../images/sidebarbg.png);
  background-position: top 0px right -67px;
  width: 75px;
  height: 85px;
  background-size: 123px;
  margin-bottom: -30px;
  transition: all 0.6s;
}

.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px;
}

.sidebar-menu ul ul a.active {
  color: #20e3ff;
  text-decoration: underline;
}

.mobile_btn {
  display: none;
  float: left;
}

.sidebar .sidebar-menu>ul>li>a span {
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
  margin-left: 5px;
  white-space: nowrap;
}

.sidebar .sidebar-menu>ul>li>a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar .sidebar-menu>ul>li>a span.badge {
  margin-left: auto;
}

.sidebar-menu ul ul a {
  display: block;
  font-size: 15px;
  padding: 7px 10px 7px 45px;
  position: relative;
}

.sidebar-menu ul ul {
  display: none;
}

.sidebar-menu ul ul ul a {
  padding-left: 65px;
}

.sidebar-menu ul ul ul ul a {
  padding-left: 85px;
}

.sidebar-menu>ul>li {
  margin-bottom: 3px;
  position: relative;
}

.sidebar-menu>ul>li:last-child {
  margin-bottom: 0;
}

.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: "FontAwesome";
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px;
}

.sidebar-menu .menu-arrow:before {
  content: "\f105";
}

/* .sidebar-menu li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
} */
.sidebar-menu ul ul a .menu-arrow {
  top: 10px;
}

.sidebar-menu>ul>li>a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 18px;
  padding: 30px 0 0px 25px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto;
}

.sidebar-menu li a>.badge {
  color: #fff;
}

/*-----------------
	6. Toggle Button
-----------------------*/
/* 
.check {
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
}
.checktoggle {
    background-color: #e0001a;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}
.checktoggle:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(5px, -50%);
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-radius: 50%;
	transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
	background-color: #55ce63;
}
.check:checked + .checktoggle:after {
	left: 100%;
	transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
	margin-left: auto;
    position: relative;
	width: 73px;
    -webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; 
	overflow: hidden; 
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
}
.onoffswitch-inner {
	display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}
.onoffswitch-inner:before {
	background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
	height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
} */
/*-----------------
	10. Content
-----------------------*/

.main-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.page-wrapper {
  margin-left: 158px;
  padding-top: 65px;
  position: relative;
  transition: all 0.4s ease;
}

.page-wrapper>.content {
  padding: 1.875rem 1.875rem 0;
}

.page-header {
  margin-bottom: 1.875rem;
}

.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}

.page-header .breadcrumb a {
  color: #333;
}

.page-title {
  color: #333;
  margin-bottom: 5px;
  text-align: start;
}

.float-right {
  float: right !important;
}

/*-----------------
	11. Login
-----------------------*/

.login-body {
  display: table;
  height: 100vh;
  min-height: 100vh;
}

.login-wrapper {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.login-wrapper .loginbox {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 1.875rem auto;
  max-width: 800px;
  min-height: 500px;
  width: 100%;
}

.login-wrapper .loginbox .login-left {
  align-items: center;
  background: linear-gradient(180deg, #1b5a90, #00d0f1);
  border-radius: 6px 0 0 6px;
  flex-direction: column;
  justify-content: center;
  padding: 80px;
  width: 400px;
  display: flex;
}

.login-wrapper .loginbox .login-right {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 40px;
  width: 400px;
}

.login-wrapper .loginbox .login-right .login-right-wrap {
  max-width: 100%;
  flex: 0 0 100%;
}

.login-wrapper .loginbox .login-right h1 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}

.account-subtitle {
  color: #4c4c4c;
  font-size: 17px;
  margin-bottom: 1.875rem;
  text-align: center;
}

.login-wrapper .loginbox .login-right .forgotpass a {
  color: #a0a0a0;
}

.login-wrapper .loginbox .login-right .forgotpass a:hover {
  color: #333;
  text-decoration: underline;
}

.login-wrapper .loginbox .login-right .dont-have {
  color: #a0a0a0;
  margin-top: 1.875rem;
}

.login-wrapper .loginbox .login-right .dont-have a {
  color: #333;
}

.login-wrapper .loginbox .login-right .dont-have a:hover {
  text-decoration: underline;
}

.social-login {
  text-align: center;
}

.social-login>span {
  color: #a0a0a0;
  margin-right: 8px;
}

.social-login>a {
  background-color: #ccc;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  margin-right: 6px;
  text-align: center;
  width: 32px;
}

.social-login>a:last-child {
  margin-right: 0;
}

.social-login>a.facebook {
  background-color: #4b75bd;
}

.social-login>a.google {
  background-color: #fe5240;
}

.login-or {
  color: #a0a0a0;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}

.or-line {
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.span-or {
  background-color: #fff;
  display: block;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 42px;
}

/*-----------------
	12. Lock Screen
-----------------------*/

.lock-user {
  margin-bottom: 20px;
  text-align: center;
}

.lock-user img {
  margin-bottom: 15px;
  width: 100px;
}

/*-----------------
	13. Notifications
-----------------------*/
.notify button,
.notify button:hover,
.notify button:active {
  background: inherit !important;
  color: #000 !important;
  font-size: 24px !important;
  border: none !important;
}

.notifications {
  padding: 0;
}

.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd;
}

.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}

.notifications .media:last-child {
  border-bottom: none;
}

.notifications-list .media a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}

.notifications-list .media a:hover {
  background-color: #fafafa;
}

.notifications-list .media>.avatar {
  margin-right: 10px;
}

.notifications-list .media-list .media-left {
  padding-right: 8px;
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
}

.topnav-dropdown-header,
.topnav-dropdown-footer {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.topnav-dropdown-footer {
  border-top: 1px solid #eee;
}

.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #333;
}

.notify .badge {
  background-color: #f60b0b;
  display: block;
  font-size: 8px;
  font-weight: bold;
  min-height: 8px;
  min-width: 0px;
  position: absolute;
  right: 12px;
  color: #fff;
  top: 15px;
}

.user-menu.nav>li>a>i {
  font-size: 1.5rem;
  line-height: 60px;
}

.noti-details {
  color: #989c9e;
  margin-bottom: 0;
}

.noti-title {
  color: #333;
}

.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notifications ul.notification-list>li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}

.notifications ul.notification-list>li:last-child {
  border-bottom: none;
}

.notifications ul.notification-list>li a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}

.notifications ul.notification-list>li a:hover {
  background-color: #fafafa;
}

.notifications ul.notification-list>li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}

.topnav-dropdown-header .notification-title {
  color: #333;
  display: block;
  float: left;
  font-size: 14px;
}

.topnav-dropdown-header .clear-noti {
  color: #f83f37;
  float: right;
  font-size: 12px;
  text-transform: uppercase;
}

.noti-time {
  margin: 0;
}

/*-----------------
	14. Login
-----------------------*/

.account-page {
  background-color: #fff;
}

.account-page .content {
  padding: 50px 0;
}

/* .login-right {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 25px;
} */

.login-header p {
  margin-bottom: 0;
}

.login-header h1 {
  font-size: 30px;
  margin-bottom: 10px;
  color: #015d38;
  text-align: center;
  line-height: 1;
}

.login-header h3 {
  font-size: 20px;
  margin-bottom: 3px;
  color: #015d38;
}

.login-header h3 a {
  color: #0de0fe;
  float: right;
  font-size: 15px;
  margin-top: 2px;
}

.login-right .dont-have {
  color: #3d3d3d;
  margin-top: 10px;
  font-size: 14px;
}

.login-right .dont-have a {
  color: #015d38;
}

.login-btn {
  font-size: 18px;
  font-weight: 500;
}

.login-or {
  color: #d4d4d4;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 0px;
  padding-top: 10px;
  position: relative;
}

.or-line {
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block;
}

.span-or {
  background-color: #fff;
  display: block;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  text-align: center;
  top: -3px;
  width: 42px;
}

.forgot-link {
  color: #015d38;
  /* display: inline-block; */
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  float: right;
}

.phone-link {
  color: #015d38;
  font-size: 16px;
}

.phone-link:hover {
  color: #83b42b;
}

.phone-link-forget {
  color: #015d38;
  font-size: 16px;
  cursor: pointer;
}

.phone-link-forget:hover {
  color: #83b42b;
}

.forgot-link:hover {
  color: #83b42b;
}

.btn-facebook {
  background-color: #3a559f;
  color: #fff;
  font-size: 13px;
  padding: 8px 12px;
}

.btn-google {
  background-color: #dd4b39;
  color: #fff;
  font-size: 13px;
  padding: 8px 12px;
}

.social-login .btn:hover,
.social-login .btn:focus {
  color: #fff;
}

/*-----------------
	15. Dashboard
-----------------------*/

.dash-widget-icon {
  align-items: center;
  display: inline-flex;
  font-size: 1.875rem;
  height: 50px;
  justify-content: center;
  line-height: 48px;
  text-align: center;
  width: 50px;
  border: 3px solid;
  border-radius: 50px;
  padding: 28px;
}

.dash-count {
  font-size: 18px;
  margin-left: auto;
}

.dash-widget-info h3 {
  margin-bottom: 10px;
}

.dash-widget-header {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.card-chart .card-body {
  padding: 8px;
}

#morrisArea>svg,
#morrisLine>svg {
  width: 100%;
}

.activity-feed {
  list-style: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
}

.activity-feed .feed-item {
  border-left: 2px solid #e4e8eb;
  padding-bottom: 19px;
  padding-left: 20px;
  position: relative;
}

.activity-feed .feed-item:last-child {
  border-color: transparent;
  padding-bottom: 0;
}

.activity-feed .feed-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #177dff;
}

.activity-feed .feed-item .feed-date {
  display: block;
  position: relative;
  color: #777;
  text-transform: uppercase;
  font-size: 13px;
}

.activity-feed .feed-item .feed-text {
  color: #777;
  position: relative;
}

.activity-feed .feed-item .feed-text a {
  color: #333;
  font-weight: 600;
}

/*-----------------
	17. Inbox
-----------------------*/

.dropdown-action {
  margin-bottom: 5px;
}

.dropdown-action .dropdown-toggle:after {
  display: none;
}

.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
  cursor: pointer;
}

.mail-list {
  list-style: none;
  padding: 0;
}

.mail-list>li>a {
  color: #333;
  display: block;
  padding: 10px;
}

.mail-list>li.active>a {
  color: #00d0f1;
  font-weight: bold;
}

.unread .name,
.unread .subject,
.unread .mail-date {
  color: #000;
  font-weight: 600;
}

.table-inbox .fa-star {
  color: #ffd200;
}

.table-inbox .starred.fa-star {
  color: #ffd200;
}

.table.table-inbox>tbody>tr>td,
.table.table-inbox>tbody>tr>th,
.table.table-inbox>tfoot>tr>td,
.table.table-inbox>tfoot>tr>th,
.table.table-inbox>thead>tr>td,
.table.table-inbox>thead>tr>th {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0;
}

.table-inbox {
  font-size: 15px;
  margin-bottom: 0;
}

.table.table-inbox thead {
  background-color: #fff;
}

.note-editor.note-frame {
  border: 1px solid #ddd;
  box-shadow: inherit;
}

.note-editor.note-frame .note-statusbar {
  background-color: #fff;
}

.note-editor.note-frame.fullscreen {
  top: 60px;
}

.note-editor.note-frame .btn-light {
  background-color: #f9f9f9;
  box-shadow: unset;
  color: #333;
}

.mail-title {
  font-weight: bold;
  text-transform: uppercase;
}

.form-control.search-message {
  border-color: #ccc;
  border-radius: 4px;
  height: 38px;
  width: 180px;
}

.table-inbox tr {
  cursor: pointer;
}

table.table-inbox tbody tr.checked {
  background-color: #ffffcc;
}

.mail-label {
  font-size: 16px !important;
  margin-right: 5px;
}

/*-----------------
	18. Mail View
-----------------------*/

.attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}

.attachments li {
  border: 1px solid #eee;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 180px;
}

.attach-info {
  background-color: #f4f4f4;
  padding: 10px;
}

.attach-file {
  color: #777;
  font-size: 70px;
  padding: 10px;
  min-height: 138px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attach-file img {
  height: auto;
  max-width: 100%;
}

.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
}

.mailview-footer .btn-white {
  margin-top: 10px;
  min-width: 102px;
}

.sender-img {
  float: left;
  margin-right: 10px;
  width: 40px;
}

.sender-name {
  display: block;
}

.receiver-name {
  color: #777;
}

.right-action {
  text-align: right;
}

.mail-view-title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.mail-view-action {
  float: right;
}

.mail-sent-time {
  float: right;
}

.inbox-menu {
  display: inline-block;
  margin: 0 0 1.875rem;
  padding: 0;
  width: 100%;
}

.inbox-menu li {
  display: inline-block;
  width: 100%;
}

.inbox-menu li+li {
  margin-top: 2px;
}

.inbox-menu li a {
  color: #333;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  text-transform: capitalize;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
}

.inbox-menu li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787;
}

.inbox-menu li a:hover,
.inbox-menu li.active a,
.inbox-menu li a:focus {
  background: rgba(33, 33, 33, 0.05);
}

.compose-btn {
  margin-bottom: 1.875rem;
}

.compose-btn a {
  font-weight: 600;
  padding: 8px 15px;
}

/*-----------------
	16. Search
-----------------------*/
.openNav .menu:hover {
  position: absolute;
  left: -200px;
  top: 73px;
}

.openNav .menu {
  top: 73px;
  transform: translate3d(200px, 0, 0);
  transition: transform 0.45s cubic-bezier(0.77, 0, 0.175, 1);
}

/**/
.openNav .new-wrapper {
  position: absolute;
  transform: translate3d(200px, 0, 0);
  width: calc(100% - 250px);
  transition: transform 0.45s cubic-bezier(0.77, 0, 0.175, 1);
}

.menu input[type="checkbox"] {
  display: none;
}

input#menu[type="checkbox"] {
  display: none;
}

.sub-menu-dropdown {
  display: none;
}

.new-wrapper {
  position: absolute;
  left: 50px;
  width: calc(100% - 50px);
  transition: transform 0.45s cubic-bezier(0.77, 0, 0.175, 1);
}

#menu:checked+ul.menu-dropdown {
  left: 0;
  -webkit-animation: all 0.45s cubic-bezier(0.77, 0, 0.175, 1);
  animation: all 0.45s cubic-bezier(0.77, 0, 0.175, 1);
}

.sub-menu-checkbox:checked+ul.sub-menu-dropdown {
  display: block !important;
  -webkit-animation: grow 0.45s cubic-bezier(0.77, 0, 0.175, 1);
  animation: grow 0.45s cubic-bezier(0.77, 0, 0.175, 1);
}

.openNav .new-wrapper {
  position: absolute;
  transform: translate3d(200px, 0, 0);
  width: calc(100% - 250px);
  transition: transform 0.45s cubic-bezier(0.77, 0, 0.175, 1);
}

.downarrow {
  background: transparent;
  position: absolute;
  right: 20px;
  top: 12px;
  color: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  display: block;
}

.sub-menu-dropdown li {
  padding-left: 0px !important;
}

.downarrow:hover {
  color: #fff;
}

.menu {
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  width: 250px;
  transition: all 0.45s cubic-bezier(0.77, 0, 0.175, 1);
  background-color: rgb(27 90 144) !important;
  z-index: 999;
}

.invoice-link {
  color: #05bdd8 !important;
}

.menu-dropdown {
  top: 0;
  overflow-y: auto;
  padding-bottom: 79px !important;
}

.overflow-container {
  position: relative;
  height: auto;
  overflow-y: auto;

  z-index: 0;
  display: block;
  background: #1b5a90;
  padding: 0px 15px;
  padding-bottom: 30px;
}

.mini-sidebar.expand-menu i {
  display: inline-block !important;
}

.mini-sidebar .downarrow i {
  display: none;
}

.menu a.logotype {
  position: absolute !important;
  top: 11px;
  left: 55px;
  display: block;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  color: #000;
  font-size: 21px;
  padding: 10px;
}

.menu a.logotype span {
  font-weight: 400;
}

.menu a.logotype:hover {
  color: #777;
}

.sub-menu-dropdown {
  background-color: #1b5a90;
}

/*-----------------
	20. Booking Success
-----------------------*/

/*-----------------
	22. Schedule Timings
-----------------------*/

/*-----------------
	16. Calendar
-----------------------*/

.calendar-events {
  border: 1px solid transparent;
  cursor: move;
  padding: 10px 15px;
}

.calendar-events:hover {
  border-color: #e9e9e9;
  background-color: #fff;
}

.calendar-events i {
  margin-right: 8px;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-family: "Roboto", sans-serif;
}

.fc-day {
  background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f3f3f3;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 2px 5px;
}

.fc-button {
  font-size: 12px !important;
  background: #e9f0de !important;
  color: #000 !important;
  border: none !important;
  text-transform: capitalize;
  box-shadow: none !important;
  /* border-radius: 3px !important;*/
  margin: 0 -2px !important;
  padding: 3px 10px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

/* .fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #78e033 !important;
	color: #fff !important;
	text-shadow: none !important;
} */
/* .fc-cell-overlay {
	background: #f0f0f0;
} */
/* .fc-unthemed .fc-today {
	background: #fff;
} */
/* .fc-event {
	border-radius: 2px;
	border: none;
	color: #fff !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
} */
/* .fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
} */
.event-form .input-group .form-control {
  height: 40px;
}

.submit-section {
  text-align: center;
  margin-top: 40px;
}

.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
  min-width: 200px;
  padding: 10px 20px;
}

/*-----------------
	20. Chat
-----------------------*/

.chat-window {
  border: 1px solid #e0e3e4;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 1.875rem;
}

.chat-window .chat-cont-left {
  border-right: 1px solid #e0e3e4;
  flex: 0 0 35%;
  left: 0;
  max-width: 35%;
  position: relative;
  z-index: 4;
}

.chat-window .chat-cont-left .chat-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e3e4;
  color: #324148;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}

.chat-window .chat-cont-left .chat-header span {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.chat-window .chat-cont-left .chat-header .chat-compose {
  color: #8a8a8a;
  display: inline-flex;
}

.chat-window .chat-cont-left .chat-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%;
}

.chat-window .chat-cont-left .chat-search .input-group {
  width: 100%;
}

.chat-window .chat-cont-left .chat-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px;
}

.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}

.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
  align-items: center;
  bottom: 0;
  color: #666;
  display: flex;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4;
}

.chat-window .chat-scroll {
  max-height: calc(100vh - 255px);
  overflow-y: auto;
}

.chat-window .chat-cont-left .chat-users-list {
  background-color: #fff;
}

.chat-window .chat-cont-left .chat-users-list a.media {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  transition: all 0.2s ease 0s;
}

.chat-window .chat-cont-left .chat-users-list a.media:last-child {
  border-bottom: 0;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap {
  margin-right: 15px;
  position: relative;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
  height: 45px;
  width: 45px;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .status {
  bottom: 7px;
  height: 10px;
  right: 4px;
  position: absolute;
  width: 10px;
  border: 2px solid #fff;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body {
  display: flex;
  justify-content: space-between;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name {
  color: #333;
  text-transform: capitalize;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
  color: #8a8a8a;
  font-size: 14px;
  line-height: 24px;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:last-child {
  text-align: right;
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body>div:last-child .last-chat-time {
  color: #8a8a8a;
  font-size: 13px;
}

.chat-window .chat-cont-left .chat-users-list a.media:hover {
  background-color: #f5f5f6;
}

.chat-window .chat-cont-left .chat-users-list a.media.read-chat .media-body>div:last-child .last-chat-time {
  color: #8a8a8a;
}

.chat-window .chat-cont-left .chat-users-list a.media.active {
  background-color: #f5f5f6;
}

.chat-window .chat-cont-right {
  flex: 0 0 65%;
  max-width: 65%;
}

.chat-window .chat-cont-right .chat-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e3e4;
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px;
}

.chat-window .chat-cont-right .chat-header .back-user-list {
  display: none;
  margin-right: 5px;
  margin-left: -7px;
}

.chat-window .chat-cont-right .chat-header .media {
  align-items: center;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .avatar {
  height: 50px;
  width: 50px;
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  position: absolute;
  right: 3px;
  width: 10px;
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-name {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-status {
  color: #666;
  font-size: 14px;
}

.chat-window .chat-cont-right .chat-header .chat-options {
  display: flex;
}

.chat-window .chat-cont-right .chat-header .chat-options>a {
  align-items: center;
  border-radius: 50%;
  color: #8a8a8a;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  margin-left: 10px;
  width: 30px;
}

.chat-window .chat-cont-right .chat-body {
  background-color: #f5f5f6;
}

.chat-window .chat-cont-right .chat-body ul.list-unstyled {
  margin: 0 auto;
  padding: 15px;
  width: 100%;
}

.chat-window .chat-cont-right .chat-body .media .avatar {
  height: 30px;
  width: 30px;
}

.chat-window .chat-cont-right .chat-body .media .media-body {
  margin-left: 20px;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box>div {
  padding: 10px 15px;
  border-radius: 0.25rem;
  display: inline-block;
  position: relative;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box>div p {
  color: #333;
  margin-bottom: 0;
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box+.msg-box {
  margin-top: 5px;
}

.chat-window .chat-cont-right .chat-body .media.received {
  margin-bottom: 20px;
}

.chat-window .chat-cont-right .chat-body .media:last-child {
  margin-bottom: 0;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box>div {
  background-color: #fff;
}

.chat-window .chat-cont-right .chat-body .media.sent {
  margin-bottom: 20px;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 0;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div {
  background-color: #e3e3e3;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div p {
  color: #333;
}

.chat-window .chat-cont-right .chat-body .chat-date {
  font-size: 14px;
  margin: 1.875rem 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}

.chat-window .chat-cont-right .chat-body .chat-date:before {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin-right: 28px;
  position: absolute;
  right: 50%;
  top: 50%;
  width: 100%;
}

.chat-window .chat-cont-right .chat-body .chat-date:after {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  left: 50%;
  margin-left: 28px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.chat-window .chat-cont-right .chat-footer {
  background-color: #fff;
  border-top: 1px solid #e0e3e4;
  padding: 10px 15px;
  position: relative;
}

.chat-window .chat-cont-right .chat-footer .input-group {
  width: 100%;
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px;
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
  background-color: #f5f5f6;
  border: none;
  box-shadow: none;
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-prepend .btn,
.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f;
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
  background-color: #00d0f1;
  border-color: #00d0f1;
  border-radius: 50%;
  color: #fff;
  margin-left: 10px;
}

.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px;
}

.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #a0a0a0;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.msg-typing span:nth-of-type(1) {
  animation: 1s blink infinite 0.33333s;
}

.msg-typing span:nth-of-type(2) {
  animation: 1s blink infinite 0.66666s;
}

.msg-typing span:nth-of-type(3) {
  animation: 1s blink infinite 0.99999s;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box {
  position: relative;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #fff;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  left: -6px;
  position: absolute;
  right: auto;
  top: 8px;
  width: 0;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box {
  padding-left: 50px;
  position: relative;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #e3e3e3;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  left: auto;
  position: absolute;
  right: -6px;
  top: 8px;
  width: 0;
}

.chat-msg-info {
  align-items: center;
  display: flex;
  clear: both;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}

.chat-msg-info li {
  font-size: 13px;
  padding-right: 16px;
  position: relative;
}

.chat-msg-info li:not(:last-child):after {
  position: absolute;
  right: 8px;
  top: 50%;
  content: "";
  height: 4px;
  width: 4px;
  background: #d2dde9;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
  right: auto;
  left: 8px;
  transform: translate(-50%, -50%);
  background: #aaa;
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box>div .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-time {
  color: rgba(50, 65, 72, 0.4);
}

.chat-msg-info li a {
  color: #777;
}

.chat-msg-info li a:hover {
  color: #2c80ff;
}

.chat-seen i {
  color: #00d285;
  font-size: 16px;
}

.chat-msg-attachments {
  padding: 4px 0;
  display: flex;
  width: 100%;
  margin: 0 -1px;
}

.chat-msg-attachments>div {
  margin: 0 1px;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-info {
  flex-direction: row-reverse;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-attachments {
  flex-direction: row-reverse;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div .chat-msg-info li {
  padding-left: 16px;
  padding-right: 0;
  position: relative;
}

.chat-attachment img {
  max-width: 100%;
}

.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
}

.chat-attachment {
  border-radius: 0.25rem;
}

.chat-attachment:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  content: "";
  opacity: 0.4;
  transition: all 0.4s;
}

.chat-attachment:hover:before {
  opacity: 0.6;
}

.chat-attach-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all 0.4s;
}

.chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}

.chat-attach-download:hover {
  color: #495463;
  background: #fff;
}

.chat-attachment:hover .chat-attach-caption {
  opacity: 0;
}

.chat-attachment:hover .chat-attach-download {
  opacity: 1;
}

.chat-attachment-list {
  display: flex;
  margin: -5px;
}

.chat-attachment-list li {
  width: 33.33%;
  padding: 5px;
}

.chat-attachment-item {
  border: 5px solid rgba(230, 239, 251, 0.5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box>div:hover .chat-msg-actions {
  opacity: 1;
}

.chat-msg-actions {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.4s;
  z-index: 2;
}

.chat-msg-actions>a {
  padding: 0 10px;
  color: #495463;
  font-size: 24px;
}

.chat-msg-actions>a:hover {
  color: #2c80ff;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.btn-file {
  align-items: center;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 0 0.75rem;
  position: relative;
  vertical-align: middle;
}

.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

/*-----------------
	29. Patient Dashboard
--

/*-----------------
	32. Reviews
-----------------------*/

/*-----------------
	35. Outgoing Call
-----------------------*/

/*-----------------
	38. Blog
--
/*-----------------
	39. Blog Details
-----------------------*/

/*-----------------
	40. Blog Grid
-----------------------*/

/*-----------------
	45. Slider
-----------------------*/


.specialities-slider .slick-list {
  height: 240px;
}

.main-nav a {
  color: #000;
}

.MuiDropzoneArea-root {
  min-height: 50px !important;
}

.MuiDropzoneArea-root p {
  font-size: 15px;
}

.dashboard-menu.nav .nav-item {
  position: relative;
}

.modal-body .react-datepicker-wrapper {
  width: 100%;
}

.gm-style-iw-d .profile-widget p.speciality {
  min-height: 18px;
}

.speicality-item:focus {
  border: none;
  outline: none !important;
}

.slick-cloned .profile-widget {
  box-shadow: none !important;
}

.section-specialities .slick-dots li {
  height: 8px;
}

.section-specialities .slick-dots li button {
  color: #c0c0c0;
  background: #c0c0c0;
}

.section-specialities .slick-dots li.slick-active button {
  background-color: #0de0fe;
}

.section-specialities .slick-dots button {
  height: 6px;
  padding: 0px;
}

.header-contact-img svg {
  font-size: 30px;
  color: #7f7f7f;
}

/*.doctor-slider home page*/
.doctor-slider .slick-prev::before {
  font-family: "Font Awesome 5 Pro";

  font-size: 22px;
  font-weight: 900;
}

.doctor-slider .slick-next::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 22px;
  font-weight: 900;
}

.doctor-slider .slick-list {
  padding: 0px;
}

/*features*/
.features-slider .slick-list {
  height: 170px;
}

.features-slider .slick-dots li {
  height: 8px;
}

.features-slider .slick-dots li button {
  color: #c0c0c0;
  background: #c0c0c0;
}

.features-slider .slick-dots li.slick-active button {
  background-color: #0de0fe;
}

.features-slider .slick-dots li button {
  height: 7px;
  padding: 0px;
}

.nav-tabs .nav-link {
  border: none;
}

.tab-view .nav-item {
  width: 25%;
  text-align: center;
  color: #000;
  font-weight: 700;
}

.tab-view .nav-item.active {
  background: none;
  color: #20c0f3;
  border-bottom: 3px solid #20c0f3;
}

.tab-view .nav-item:focus,
.tab-view .nav-item:hover {
  background: none;
  color: #20c0f3;
}

.sortby-fliter:focus {
  border: 1px solid #000;
}

.datepicker-icon {
  max-width: 253px;
  float: right;
  position: relative;
}

.datepicker-icon input {
  max-width: 253px;
  position: relative;
  padding-left: 40px;
  padding-right: 26px;
}

.datepicker-icon i {
  position: absolute;
  left: 6px;
  z-index: 1;
  top: 14px;
  width: 20px;
}

.datepicker-icon i.fas {
  right: 6px !important;
  left: auto;
}

.custom-checkbox label {
  margin-left: 3px;
}

.tab-view a {
  padding: 20px 1rem;
}

.RCP {
  margin-right: 5px;
  width: 105px !important;
}

.RCP svg {
  width: 100px;
  position: relative;
  height: 100px;
}

.RCP .indicator-volume {
  position: absolute;
  position: absolute;
  top: 30px;
  margin-top: 0px;
  left: 35px;
  width: 28px;
}

/* .has-submenu:hover > .submenu{display: block;} */
.submenu.active {
  display: block;
}

.schedule-nav .nav-tabs a.active {
  background: #ff4877;
  border: 1px solid #ff4877 !important;
  color: #fff;
}

.schedule-nav a {
  padding: 7px 5px;
  margin: 0px 5px;
  font-weight: normal;
}

.schedule-nav .nav-item {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.schedule-nav .tab-view .nav-item {
  width: 113px;
  font-weight: normal;
}

.schedule-nav .tab-content .nav-item {
  border: none;
}

.schedule-nav .nav-item:hover {
  background: #eee !important;
  color: #000 !important;
}

.schedule-nav .tab-content .nav-item:hover {
  background: #fff !important;
}

.btn-sm {
  margin: 0px 2px;
}

.clinic-gallery a {
  cursor: pointer;
}

.cal-icon .form-control {
  border: none !important;
}

.cal-icon {
  border: 1px solid #ced4da;
}

/* .doc-img {
	position: absolute;    z-index: 0;} */
.map-right {
  z-index: 1;
}

.fc-button-primary.fc-button-active {
  background-color: #83b42b !important;
  color: #ffff !important;
}

.fc-prev-button {
  background-color: #83b42b !important;
  color: #fff !important;
}

.fc-next-button {
  background-color: #83b42b !important;
  color: #fff !important;
  border-radius: 2rem;
  padding: 4px;
}

.ReactTags__tagInputField {
  width: 100%;
  border: 1px solid #dcdcdc;
  padding: 10px;
  margin-top: 10px;
}

.ReactTags__tags span {
  color: #fff;
  opacity: 1;
  cursor: move;
  background: #20c0f3;
  padding: 6px;
  margin: 5px;
}

.ReactTags__tagInput input:focus {
  border: 1px solid #dcdcdc !important;
  outline: none;
}

.delete-icon {
  display: inline-block;
}

.user-drop button,
.user-drop button:hover {
  background-color: inherit !important;
  border: none !important;
}

.dashboard-menu.nav a {
  color: #757575;
  display: block;
  padding: 16px 20px;
}

.dashboard-menu.nav a span,
.dashboard-menu.nav a i {
  display: inline-block;
  vertical-align: middle;
  padding-right: 15px;
}

.dashboard-menu.nav .nav-item {
  width: 100%;
}

.dashboard-menu.nav span:hover {
  color: #0de0fe;
}

.dashboard-menu.nav a.active {
  color: #0de0fe;
}

.appointment-tab .nav-tabs a.active {
  background: #20c0f3;
  color: #ffffff;
  border-bottom: 3px solid #20c0f3;
  padding: 0px;
  border-radius: 30px;
  /* padding: 5px; */
  width: 130px;
  height: 45px;
  line-height: 45px;
  font-weight: normal;
}

.appointment-tab .nav-tabs a {
  width: 130px;
  height: 45px;
  line-height: 45px;
  padding: 0px;
  font-weight: normal;
}

.header-navbar-rht .dropdown-menu.show {
  top: 20px;
}

.doctor-grid .profile-widget .doc-img {
  z-index: 0;
}

.slick-current div:focus {
  outline: none !important;
}

/* sidemenu */
.sideBarMenuContainer {
  position: fixed;
  height: 100%;
}

.userMenu {
  width: 100%;
  text-align: center;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
}

nav.sidebar :global(.navbar-brand) :global(.glyphicon) {
  margin-right: 0px;
}

/*Remove rounded coners*/
nav.sidebar:global(navbar) {
  border-radius: 0px;
}

nav.sidebar {
  -webkit-transition: margin 200ms ease-out;
  -moz-transition: margin 200ms ease-out;
  -o-transition: margin 200ms ease-out;
  transition: margin 200ms ease-out;
}

/*small/medium side display*/


.mini-sidebar.expand-menu .menu-title {
  visibility: visible;
}

.mini-sidebar .menu-title a {
  visibility: hidden;
}

.mini-sidebar.expand-menu .menu-title a {
  visibility: visible;
}

.modal-open.mini-sidebar .sidebar {
  z-index: 1051;
}

.mini-sidebar .sidebar .sidebar-menu ul>li>a span {
  display: none;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.mini-sidebar.expand-menu .sidebar .sidebar-menu ul>li>a span {
  display: inline;
  opacity: 1;
}

.mini-sidebar.expand-menu .sidebar .sidebar-menu>ul>li>a i {
  font-size: 24px;
  width: 20px;
}

.mini-sidebar .page-wrapper {
  margin-left: 105px;
}

/*-----------------
	46. Responsive
-----------------------*/

@media only screen and (max-width: 1199px) {
  .header-nav {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  } */
  .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
  .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 150px;
  }

  .chat-cont-left {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .chat-cont-right {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .row.row-grid>div {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .dash-widget {
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .circle-bar {
    margin: 0 0 15px;
  }

  .header-navbar-rht li.contact-item {
    display: none;
  }

  .map-right {
    height: 450px;
    position: static;
    min-height: inherit;
    margin-bottom: 30px;
    padding-right: 15px;
  }

  .map-listing {
    height: 450px;
  }

  .map-page .content {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 992px) {
  .custom_card_inner_styling_login_page {
    position: relative;
    top: 150px;
    left: 35%;
    /* transform: translate(-50%,-50%); */
    padding: 0px 20px;
    background-color: #ffffff50;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    width: 30%;
  }

  .custom_card_inner_styling_verifyOtp_signUp_page {
    position: relative;
    top: 120px;
    left: 200px;
    padding: 0px 10px;
  }

  .custom_card_styling_Subscription_page {
    margin-top: 80px;
    /* margin-left: 400px; */
  }

  .custom_card_inner_styling_signUp_page {
    position: relative;
    top: 100px;
    left: 200px;
    padding: 0px 10px;
  }

  .Subscription_card_box {
    position: relative;
    margin-top: 60px;
    left: 30px;
    width: 90%;
  }

  .input-verification .styles_react-code-input__CRulA>input:first-child {
    width: 40px !important;
    height: 40px !important;
  }

  .input-verification .styles_react-code-input__CRulA>input {
    width: 40px !important;
    height: 40px !important;
  }

  .account-content_confirm {
    margin-top: 40px;
  }

  .main-nav>li {
    margin-right: 30px;
  }

  .white-font>li>a {
    color: #fff;
  }

  .main-nav>li:last-child {
    margin-right: 0;
  }

  .main-nav li {
    display: block;
    position: relative;
  }

  .main-nav>li>a {
    line-height: 85px;
    padding: 0 !important;
  }

  .main-nav>li>a>i {
    font-size: 12px;
    margin-left: 3px;
  }

  .main-nav li>ul {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    display: block;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 200px;
    opacity: 0;
    padding: 0;
    position: absolute;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    top: 100%;
    visibility: hidden;
    z-index: 1000;
  }

  .main-nav li .submenu::before {
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
    content: "";
    left: 45px;
    position: absolute;
    top: 2px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .main-nav li.has-submenu:hover>.submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  .main-nav .has-submenu.active>a {
    color: #09dca4;
  }

  .main-nav .has-submenu.active .submenu li.active>a {
    color: #09dca4;
  }

  .main-nav>li .submenu li:first-child a {
    border-top: 0;
  }

  .main-nav>li.has-submenu:hover>.submenu>li.has-submenu:hover>.submenu {
    visibility: visible;
    opacity: 1;
    margin-top: -1px;
    margin-right: 0;
  }

  .main-nav>li .submenu>li .submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }

  .main-nav li .submenu a:hover {
    color: #09dca4;
    letter-spacing: 0.5px;
    padding-left: 20px;
  }

  .main-nav>.has-submenu>.submenu>.has-submenu>.submenu::before {
    top: 20px;
    margin-left: -35px;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
    border-color: transparent #fff #fff transparent;
  }

  .header-navbar-rht li.show>.dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  .header-navbar-rht li .dropdown-menu {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    min-width: 200px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: block;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  .timeslot-modal-section-styling {
    margin-top: 0px;
    /* height: 70vh; */
  }

  .doctors-specialites-row {
    margin-top: 0;
  }

  .timeslot-modal-searchfield {
    width: 50%;
    right: 0;
    position: absolute;
  }

  .account-content {
    margin-top: 45px;
  }

  .apexcharts-legend-text {
    font-size: 10px !important;
  }

  .donut {
    margin-left: -35px !important;
  }

  .small_right_icon {
    background-color: rgba(146, 135, 135, 0.3);
    opacity: 1;
    border-radius: 50%;
    padding: 5px;
  }

  .dots_icon {
    float: right;
    display: inline-grid;
    font-size: 10px;
  }

  .count_right {
    margin-left: 5px;
    font-size: 10px;
    font-weight: 500;
  }

  .function_card {
    width: 7rem;
    border-radius: 10px;
    border: none;
  }

  .small_right_icon {
    background-color: rgba(146, 135, 135, 0.3);
    opacity: 1;
    border-radius: 50%;
    padding: 5px;
  }

  .dots_icon {
    float: right;
    display: inline-grid;
    font-size: 10px;
  }

  .count_right {
    margin-left: 5px;
    font-size: 10px;
    font-weight: 500;
    font-size: 20px;
  }

  .function_card {
    width: 9rem;
    border-radius: 10px;
    border: none;
  }

  /*xeven style end*/
  .toggle_btn {
    align-items: center;
    color: #fff;
    display: inline-flex;
    float: left;
    font-size: 30px;
    height: 60px;
    justify-content: center;
    margin-left: 100px;
    padding: 0 15px;
    cursor: pointer;
    transition: margin-left 0.5s;
  }

  .toggle_btn_widthset {
    align-items: center;
    color: #fff;
    display: inline-flex;
    float: left;
    font-size: 30px;
    height: 60px;
    justify-content: center;
    margin-left: 20px;
    padding: 0 15px;
    cursor: pointer;
    transition: margin-left 0.3s;
  }

  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 40px;
    width: auto;
  }

  .mini-sidebar .header .header-left .logo {
    display: none;
  }

  .mini-sidebar .header-left .logo.logo-small {
    display: block;
    width: 70px;
  }

  .mini-sidebar .header .header-left {
    padding: 0 5px;
    width: 78px;
  }

  .mini-sidebar .sidebar {
    width: 78px;
  }

  .mini-sidebar.expand-menu .sidebar {
    width: 143px;
  }

  .mini-sidebar .menu-title {
    white-space: nowrap;
  }

  .mini-sidebar .menu-title img {
    width: 30px;
    white-space: nowrap;
    margin-left: 8px;
    transition: width 0.6s;
  }

  .sidebar:hover .menu-title img {
    width: 70px;
    margin-left: 21px;
  }

  .accept_button_small {
    background: #83b42b;
    font-size: 12px;
    padding: 8px 12px;
    border-color: #83b42b;
    border-radius: 10px;
  }

  .reject_button_small {
    background: #f00000;
    font-size: 12px;
    padding: 8px 15px;
    border-color: #b01c1c;
    border-radius: 10px;
  }

  .image_box_sidebar img {
    width: 100px;
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px !important;
    border: 1px solid #005d38;
  }

  .Rs_file_box img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 80px;
    height: 80px;
    border: 1px solid #9c9c9c;
    background-color: #005d38;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 991.98px) {
  .custom_card_inner_styling_login_page {
    position: relative;
    margin-top: 40px;
    /* margin-bottom: 120px; */
    padding: 0px 0px;
    width: 70%;
    left: 100px;
    background-color: #ffffff50;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .setting_verification_menu {
    display: flex;
    flex-direction: column;
    max-width: fit-content;
  }

  .vertical-line {
    display: none;
    /* padding: 2px;
    border-left: 2px solid #d6d6d6;
    height: 100%;
    float: right;
    position: absolute;
    top: 10px; */
  }

  .side_images_box {
    display: none;
  }

  .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .main-nav ul {
    background-color: #3474ac;
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  .main-nav>li {
    border-bottom: 1px solid #1663a6;
    margin-left: 0;
  }

  .main-nav li+li {
    margin-left: 0;
  }

  .main-nav>li>a {
    line-height: 1.5;
    padding: 15px 20px !important;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }

  .main-nav>li>a>i {
    float: right;
    margin-top: 5px;
  }

  .main-nav>li .submenu li a {
    border-top: 0;
    color: #fff;
    padding: 10px 15px 10px 35px;
  }

  .main-nav>li .submenu ul li a {
    padding: 10px 15px 10px 45px;
  }

  .main-nav>li .submenu>li.has-submenu>a::after {
    content: "\f078";
  }

  .login-left {
    display: none;
  }

  .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1060;
    transform: translateX(-260px);
    transition: all 0.4s;
    background-color: #15558d;
  }

  .menu-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  #mobile_btn {
    display: inline-block;
  }

  .section-search {
    min-height: 330px;
  }

  .section-specialities {
    padding: 50px 0;
  }

  .footer-widget {
    margin-bottom: 30px;
  }

  .chat-cont-left,
  .chat-cont-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
    width: 100%;
  }

  .chat-cont-left {
    border-right: 0;
  }

  .chat-cont-right {
    position: absolute;
    right: -100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }

  .chat-cont-right .chat-header {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }

  .chat-cont-right .chat-header .back-user-list {
    display: block;
  }

  .chat-cont-right .chat-header .chat-options {
    margin-left: auto;
  }

  .chat-window.chat-slide .chat-cont-left {
    left: -100%;
  }

  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .day-slot li.left-arrow {
    left: -10px;
  }

  .container {
    max-width: 100%;
  }

  .appointments .appointment-action {
    margin-top: 10px;
  }

  .appointments .appointment-list {
    display: block;
  }

  .banner-wrapper {
    max-width: 720px;
  }

  .search-box .search-info {
    -ms-flex: 0 0 410px;
    flex: 0 0 410px;
    width: 410px;
  }

  .banner-wrapper .banner-header h1 {
    font-size: 2.125rem;
  }

  .dct-border-rht {
    border-bottom: 1px solid #f0f0f0;
    border-right: 0;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }

  .dr-img {
    display: none;
  }

  .search-box-3 {
    position: relative;
  }

  .header-top {
    display: none;
  }

  .search-box {
    max-width: 535px;
    margin: 0 auto;
  }

  .card-label>label {
    font-size: 12px;
  }

  .footer .footer-top {
    padding-bottom: 10px;
  }

  .time-slot li .timing.selected::before {
    display: none;
  }

  .review-listing .recommend-btn {
    float: none;
  }

  .dash-widget {
    flex-direction: unset;
    text-align: left;
  }

  .circle-bar {
    margin: 0 15px 0 0;
  }

  .call-wrapper {
    height: calc(100vh - 140px);
  }

  .sidebar-overlay.opened {
    display: block;
  }

  .about-content {
    margin-bottom: 30px;
  }

  .slide-image {
    padding-left: 0;
    padding-right: 0;
  }

  .custom-short-by>div {
    margin-right: 0;
  }

  .product-description .doctor-img1 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .product-description .doc-info-cont {
    width: 100%;
  }

  .product-description .doc-info-left {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
  }
}

@media (max-width: 993px) {
  .specialities-slider .slick-list {
    height: 120px;
  }

  .speicality-img {
    position: relative;
    height: 65px;
    box-shadow: inherit;
  }
}

@media only screen and (max-width: 767.9px) {
  .credit_card_icons {
    color: #8f8f8f;
    top: 25px;
    right: 25px;
    position: absolute;
  }

  .right_side_box {
    margin-left: 20px;
  }

  .payment_Button_form_div {
    margin-right: 0px;
  }

  .payment_form_div {
    margin: 10px 0px;
  }

  .payment_details_div {
    margin: 10px 0px;
  }

  .custom_card_inner_styling_login_page {
    position: relative;
    margin-top: 10px;
    margin-bottom: 120px;
    padding: 0px 0px;
    width: 80%;
    left: 20px;
    background-color: #ffffff50;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .custom_card_inner_styling_verifyOtp_signUp_page {
    position: relative;
    margin-top: 0px !important;
    left: 15px !important;
    width: 95%;
    padding: 0px 10px !important;
  }

  .custom_card_inner_styling_signUp_page {
    position: relative;
    margin-top: 120px !important;
    margin-bottom: 50px !important;
    left: 5px;
    padding: 0px 10px;
  }

  .Subscription_card_box {
    position: relative;
    margin-top: 130px;
    width: 95%;
    left: 15px;
  }

  .custom_card_styling_Subscription_page {
    margin-top: 80px;
  }

  .sage_mini_logo {
    top: 0;
    right: 0;
    position: absolute;
    background-color: #005d38;
    width: fit-content;
    padding: 10px 10px;
    border-radius: 50%;
    box-shadow: 1px 3px 12px #0000005b;
  }

  .doc_image>.rightside_image {
    display: none;
  }

  .login_footer {
    right: 0;
    text-align: center;
    margin-top: 30px;
    background: #e5e5e5;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
  }

  body {
    font-size: 0.9375rem;
  }

  h2,
  .h2 {
    font-size: 1.6rem;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
  }

  h4,
  .h4 {
    font-size: 1.125rem;
  }

  h5,
  .h5 {
    font-size: 1rem;
  }

  h6,
  .h6 {
    font-size: 0.875rem;
  }

  .header_dashboard img {
    width: 23%;
  }

  .header_dashboard h1 {
    font-size: x-large;
  }

  .header .has-arrow .dropdown-toggle:after {
    display: none;
  }

  .user-menu.nav>li>a>span:not(.user-img) {
    display: none;
  }

  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }

  .navbar-nav.user-menu .open .dropdown-menu {
    left: auto;
    right: 0;
  }

  .header .header-left {
    padding: 0 15px;
  }

  .header .header-left .logo {
    display: none;
  }

  .header-left .logo.logo-small {
    display: inline-block;
  }

  .login-wrapper .loginbox .login-left {
    display: none;
  }

  .login-wrapper .loginbox {
    max-width: 450px;
    min-height: unset;
  }

  .login-wrapper .loginbox .login-right {
    float: none;
    padding: 1.875rem;
    width: 100%;
  }

  .invoice-container {
    padding: 20px;
  }

  .left-action {
    text-align: center;
    margin-bottom: 15px;
  }

  .right-action {
    text-align: center;
  }

  .top-action-left .float-left {
    float: none !important;
  }

  .top-action-left .btn-group {
    margin-bottom: 15px;
  }

  .top-action-right {
    text-align: center;
  }

  .top-action-right a.btn.btn-white {
    margin-bottom: 15px;
  }

  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }

  .profile-btn {
    flex: 0 0 100%;
    margin-top: 20px;
  }

  .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
  .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 250px;
  }

  .app-dropdown {
    display: none;
  }

  .edit-link {
    font-size: 0.875rem;
    margin-top: 0;
  }

  .product_price {
    font-size: 1.5rem;
  }

  .login-wrapper .loginbox .login-right h1 {
    font-size: 22px;
  }

  .error-box h1 {
    font-size: 6em;
  }

  .error-box .btn {
    font-size: 15px;
    min-width: 150px;
    padding: 8px 20px;
  }

  .dash-count {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .Request_images_card {
    width: fit-content;
    border-radius: 1rem;
    box-shadow: 1px 3px 8px #00000029;
  }
  .Bottom_ribbon {
    flex: none;
    max-width: fit-content;
    background: #015d38;
    color: #fff;
    border-radius: 10px 10px 0px 10px;
  }

  .verification-form-heading {
    margin-top: 60px;
    text-align: center;
    color: #015d38;
    font-family: "Poppins";
    font-weight: 300;
  }

  .verification-form-footer {
    text-align: center;
    color: #999999;
    font-family: "Poppins";
    font-weight: 300;
  }

  .verification-form {
    margin-top: 20px;
  }

  .verification-form .form-control {
    width: 90px;
    height: 90px;
    background-color: #e1e1e1;
    font-size: 25px !important;
    color: rgb(31, 26, 26);
    border: none;
  }

  .form-agreement-text {
    text-align: center;
  }

  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }

  .avatar-xxl .border {
    border-width: 4px !important;
  }

  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }

  .avatar-xxl .avatar-title {
    font-size: 42px;
  }

  .avatar-xxl.avatar-away:before,
  .avatar-xxl.avatar-offline:before,
  .avatar-xxl.avatar-online:before {
    border-width: 4px;
  }
}

@media only screen and (max-width: 1399px) {

  .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
  .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 180px;
  }

  .row.row-grid {
    margin-left: -8px;
    margin-right: -8px;
  }

  .row.row-grid>div {
    padding-right: 8px;
    padding-left: 8px;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media only screen and (max-width: 849.98px) {
  .row.row-grid>div {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767.98px) {
  body {
    font-size: 0.875rem;
  }

  h1,
  .h1 {
    font-size: 2rem;
  }

  h2,
  .h2 {
    font-size: 1.75rem;
  }

  h3,
  .h3 {
    font-size: 1.375rem;
  }

  h4,
  .h4 {
    font-size: 1rem;
  }

  h5,
  .h5 {
    font-size: 0.875rem;
  }

  h6,
  .h6 {
    font-size: 0.75rem;
  }

  .content {
    padding: 15px 0 0;
  }

  .account-page .content {
    padding: 15px 0;
  }

  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
  }

  .carousel-item {
    height: auto;
    min-height: auto;
  }

  .card {
    margin-bottom: 20px;
  }

  .profile-sidebar {
    margin-bottom: 20px;
  }

  .appointment-tab {
    margin-bottom: 20px;
  }

  .features-slider .slick-dots,
  .features-img img {
    text-align: center;
    margin: 0 auto;
  }

  .doctor-slider {
    margin-top: 25px;
  }

  .breadcrumb-bar {
    height: auto;
  }

  .login-right {
    margin: 0 15px;
    padding: 15px;
  }

  .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
  .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 250px;
  }

  .chat-window .chat-cont-right .chat-header .media .media-body {
    display: none;
  }

  .banner-wrapper .banner-header h1 {
    font-size: 2rem;
  }

  .banner-wrapper .banner-header p {
    font-size: 1rem;
  }

  .section-header h2 {
    font-size: 1.875rem;
  }

  .section-header .sub-title {
    font-size: 0.875rem;
  }

  .speicality-item p {
    font-size: 0.875rem;
  }

  .section-header p {
    font-size: 0.9375rem;
  }

  .footer-title {
    font-size: 1.125rem;
  }

  .search-box {
    max-width: 535px;
    margin: 0 auto;
  }

  .search-box form {
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    background-clip: border-box;
  }

  .search-box .search-location {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }

  .search-box .search-info {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }

  .search-box .search-btn {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    min-height: 46px;
    width: 100%;
  }

  .search-box .search-btn span {
    display: inline-block;
    margin-left: 5px;
    text-transform: uppercase;
  }

  .section-search {
    background: #f9f9f9;
  }

  .day-slot li span {
    font-size: 16px;
    text-transform: unset;
  }

  .time-slot li .timing span {
    display: block;
  }

  .submit-section.proceed-btn {
    margin-bottom: 20px;
  }

  .day-slot li small.slot-year {
    display: none;
  }

  .success-cont h3 {
    font-size: 22px;
  }

  .view-inv-btn {
    font-size: 14px;
    padding: 10px 30px;
  }

  .invoice-info.invoice-info2 {
    text-align: left;
  }

  .invoice-item .invoice-details {
    text-align: left;
  }

  .section-search,
  .section-doctor,
  .section-features {
    padding: 50px 0;
  }

  .slick-next {
    right: -10px;
  }

  .slick-prev {
    left: -10px;
  }

  .specialities-slider .slick-slide {
    margin-right: 15px;
  }

  .about-content a {
    padding: 12px 20px;
  }

  .submit-section .submit-btn {
    padding: 10px 20px;
    font-size: 15px;
    min-width: 105px;
  }

  .policy-menu {
    margin-top: 10px;
    text-align: left;
  }

  .booking-doc-info .booking-doc-img {
    width: 75px;
  }

  .booking-doc-info .booking-doc-img img {
    height: 75px;
    width: 75px;
  }

  .btn.btn-danger.trash {
    margin-bottom: 20px;
  }

  .nav-tabs.nav-tabs-bottom>li>a.active,
  .nav-tabs.nav-tabs-bottom>li>a.active:hover,
  .nav-tabs.nav-tabs-bottom>li>a.active:focus {
    background-color: #f5f5f5;
  }

  .nav-tabs.nav-justified {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs.nav-justified>li>a.active,
  .nav-tabs.nav-justified>li>a.active:hover,
  .nav-tabs.nav-justified>li>a.active:focus {
    border-color: transparent transparent transparent #20c0f3;
    border-left-width: 2px;
  }

  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 5px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0;
  }

  .nav-tabs>li>a {
    border-width: 2px;
    border-left-color: transparent;
  }

  .nav-tabs .nav-link {
    border-width: 2px;
  }

  .nav-tabs>li>a:hover,
  .nav-tabs>li>a:focus {
    background-color: #fafafa;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs>li>a.active,
  .nav-tabs>li>a.active:hover,
  .nav-tabs>li>a.active:focus {
    border-color: transparent transparent transparent #20c0f3 !important;
    border-left-width: 2px;
  }

  .nav-tabs>li.open:not(.active)>a,
  .nav-tabs>li.open:not(.active)>a:hover,
  .nav-tabs>li.open:not(.active)>a:focus {
    background-color: #fafafa;
  }

  .nav-tabs.nav-tabs-solid {
    padding: 5px;
  }

  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }

  .nav-tabs.nav-tabs-solid>li>a {
    border-left-width: 0 !important;
  }

  .nav-tabs-justified {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs-justified>li>a.active,
  .nav-tabs-justified>li>a.active:hover,
  .nav-tabs-justified>li>a.active:focus {
    border-width: 0 0 0 2px;
    border-left-color: #20c0f3;
  }

  .review-listing>ul li .comment .comment-body .meta-data span.comment-date {
    margin-bottom: 5px;
  }

  .review-listing>ul li .comment .comment-body .meta-data .review-count {
    position: unset;
  }

  .my-video ul li {
    width: 50px;
  }

  .call-users ul li {
    width: 50px;
  }

  .call-mute {
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    width: 50px;
  }

  .call-duration {
    font-size: 24px;
  }

  .voice-call-avatar .call-avatar {
    height: 100px;
    width: 100px;
  }

  .user-tabs {
    margin-top: 1.5rem;
  }

  .user-tabs .nav-tabs>li>a {
    border-left: 2px solid transparent;
    border-bottom: 0;
    padding: 0.5rem 1rem;
  }

  .user-tabs .nav-tabs.nav-tabs-bottom>li>a.active,
  .user-tabs .nav-tabs.nav-tabs-bottom>li>a.active:hover,
  .user-tabs .nav-tabs.nav-tabs-bottom>li>a.active:focus {
    border-left-width: 2px;
    color: #20c0f3;
  }

  .doctor-widget {
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .doc-info-right {
    margin-left: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .doc-info-left {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .clinic-services {
    display: none;
  }

  .doctor-img {
    margin: 0 auto 20px;
  }

  .doctor-action {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  }

  .row.row-grid>div {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .breadcrumb-bar .breadcrumb-title {
    font-size: 18px;
  }

  .dash-widget h6 {
    font-size: 15px;
  }

  .dash-widget h3 {
    font-size: 20px;
  }

  .dash-widget p {
    font-size: 13px;
  }

  .doctor-widget .doc-name {
    font-size: 18px;
  }

  .exp-title,
  .booking-total ul li span,
  .booking-total ul li .total-cost {
    font-size: 14px;
  }

  .invoice-item .customer-text {
    font-size: 16px;
  }

  .call-wrapper {
    height: calc(100vh - 115px);
  }

  .appointment-tab .nav-tabs {
    padding: 1.5rem;
  }

  .submit-btn-bottom {
    margin-bottom: 20px;
  }

  .service-list ul li {
    width: 50%;
  }

  .grid-blog .blog-title {
    min-height: inherit;
  }

  .blog-title {
    font-size: 20px;
  }

  .blog-view .blog-title {
    font-size: 22px;
  }

  .full-slide-home .slick-prev {
    left: 15px;
  }

  .full-slide-home .slick-next {
    right: 15px;
  }

  .search-box1 form p {
    margin-bottom: 15px !important;
  }

  .search-box1 .search-location1 {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .product-description .doctor-widget {
    text-align: left;
  }

  .pharmacy-home-slider .swiper-button-prev,
  .pharmacy-home-slider .swiper-button-next {
    display: none;
  }
}

@media only screen and (max-width: 575.98px) {
  .custom_card_inner_styling_login_page {
    position: relative;
    margin-top: 120px;
    margin-bottom: 120px;
    padding: 0px 0px;
    width: 90%;
    left: 20px;
    background-color: #ffffff50;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  body {
    font-size: 0.8125rem;
  }

  h1,
  .h1 {
    font-size: 1.75rem;
  }

  h2,
  .h2 {
    font-size: 1.5rem;
  }

  h3,
  .h3 {
    font-size: 1.25rem;
  }

  h4,
  .h4 {
    font-size: 1rem;
  }

  h5,
  .h5 {
    font-size: 0.875rem;
  }

  h6,
  .h6 {
    font-size: 0.75rem;
  }

  .side_images_box {
    display: none;
  }

  .card {
    margin-bottom: 0.9375rem;
  }

  .card-body {
    padding: 1.25rem;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
  }

  .header-nav {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-navbar-rht {
    display: none;
  }

  .main-nav li.login-link {
    display: block;
  }

  .navbar-header {
    width: 100%;
  }

  #mobile_btn {
    left: 0;
    margin-right: 0;
    padding: 0 15px;
    position: absolute;
    z-index: 99;
  }

  .navbar-brand.logo {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }

  .navbar-brand.logo img {
    height: 40px;
  }

  .search-box form {
    display: block;
  }

  .search-box .search-location {
    width: 100%;
    -ms-flex: none;
    flex: none;
  }

  .search-box .search-info {
    width: 100%;
    -ms-flex: none;
    flex: none;
  }

  .banner-wrapper .banner-header h1 {
    font-size: 28px;
  }

  .slick-next {
    right: 0px;
  }

  .section-header h2 {
    font-size: 1.5rem;
  }

  .section-header .sub-title {
    font-size: 0.875rem;
  }

  .speicality-item p {
    font-size: 0.875rem;
  }

  .section-header p {
    font-size: 0.9375rem;
  }

  .footer-title {
    font-size: 1.125rem;
  }

  .booking-schedule.schedule-widget {
    overflow-x: auto;
  }

  .booking-schedule.schedule-widget>div {
    width: 730px;
  }

  .booking-schedule .day-slot ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .booking-schedule .day-slot li {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    width: 100px;
  }

  .booking-schedule .time-slot ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .booking-schedule .time-slot li {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    width: 100px;
  }

  .booking-schedule .time-slot li .timing span {
    display: inline-block;
  }

  .booking-schedule .day-slot li.right-arrow {
    right: -20px;
  }

  .booking-doc-info .booking-doc-img {
    width: 70px;
  }

  .booking-doc-info .booking-doc-img img {
    height: 70px;
    width: 70px;
  }

  .voice-call-avatar .call-avatar {
    height: 80px;
    width: 80px;
  }

  .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }

  .end-call {
    margin-top: 10px;
    position: inherit;
  }

  .user-tabs .med-records {
    min-width: 110px;
  }

  .pagination-lg .page-link {
    font-size: 1rem;
    padding: 0.5rem 0.625rem;
  }

  .row.row-grid>div {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .edit-link {
    font-size: 14px;
    margin-top: 2px;
  }

  .invoice-content {
    padding: 1.25rem;
  }

  .change-avatar .profile-img img {
    height: 80px;
    width: 80px;
  }

  .submit-btn-bottom {
    margin-bottom: 0.9375rem;
  }

  .service-list ul li {
    width: 100%;
  }

  .blog {
    padding: 1.25rem;
  }

  .blog-title {
    font-size: 18px;
  }

  .blog-view .blog-title {
    font-size: 20px;
  }

  .blog-info i {
    font-size: 14px;
  }

  .post-left ul li {
    margin-right: 10px;
  }

  .post-left ul li:last-child {
    margin-right: 10px;
  }

  .about-author-img {
    height: 60px;
    width: 60px;
  }

  .author-details {
    margin-left: 80px;
  }

  .blog-comments .comments-list li img.avatar {
    border-radius: 40px;
    height: 40px;
    width: 40px;
  }

  .blog-comments .comments-list li {
    padding: 10px 0 0 50px;
  }

  .read-more {
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .section-search {
    min-height: 410px;
    padding: 30px 15px;
  }

  .specialities-slider .slick-slide {
    margin-right: 10px;
  }

  .speicality-img {
    width: 120px;
    height: 120px;
  }

  .speicality-img img {
    width: 50px;
  }

  .clinic-booking a.view-pro-btn {
    width: 100%;
    margin-bottom: 15px;
  }

  .clinic-booking a.apt-btn {
    width: 100%;
  }

  .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
  .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 160px;
  }

  .section-features,
  .section-features,
  .section-specialities {
    background-color: #fff;
    padding: 30px 0;
  }

  .login-header h3 a {
    color: #0de0fe;
    float: none;
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
    display: block;
  }

  .login-header h3 {
    text-align: center;
  }

  .appointments .appointment-list {
    text-align: center;
  }

  .appointment-list .profile-info-widget {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .appointment-list .profile-info-widget {
    text-align: center;
  }

  .appointment-list .profile-info-widget .booking-doc-img {
    margin: 0 0 15px;
  }

  .appointment-list .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }

  .appointment-list .profile-det-info {
    margin-bottom: 15px;
  }

  .appointments .appointment-action {
    margin-top: 0;
  }

  .user-tabs .nav-tabs .nav-item {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .review-listing .recommend-btn span {
    display: block;
    margin-bottom: 10px;
  }

  .review-listing>ul li .comments-reply {
    margin-left: 0;
  }

  .schedule-nav .nav-tabs li {
    display: block;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-right: 0;
  }

  .fc-header-toolbar .fc-left {
    margin-bottom: 10px;
  }

  .fc-header-toolbar .fc-right {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .specialities-slider .slick-list {
    height: 120px;
  }

  .speicality-img {
    height: 65px;
    box-shadow: inherit;
  }
}

@media only screen and (min-width: 768px) {
  .profile_modal_text {
    color: #015d38;
    font-size: 28px;
    font-weight: 500;
  }

  .profile_modal_name {
    color: #000;
    font-size: 28px;
    font-weight: 500;
  }


  .login_footer {
    right: 0;
    text-align: center;
    margin-top: 30px;
    text-align: center;
    background: #e5e5e5;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
  }

  .dots_icon {
    float: right;
    display: inline-grid;
    font-size: 10px;
  }

  .count_right {
    margin-left: 5px;
    font-size: 10px;
    font-weight: 500;
  }

  .function_card {
    width: 7rem;
    border-radius: 10px;
    border: none;
  }

  .small_right_icon {
    background-color: rgba(146, 135, 135, 0.3);
    opacity: 1;
    border-radius: 50%;
    padding: 5px;
  }

  .dots_icon {
    float: right;
    display: inline-grid;
    font-size: 10px;
  }

  .count_right {
    margin-left: 5px;
    font-size: 10px;
    font-weight: 500;
  }

  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }

  .avatar-xxl .border {
    border-width: 4px !important;
  }

  .avatar-xxl .rounded {
    border-radius: 12px !important;
  }

  .avatar-xxl .avatar-title {
    font-size: 42px;
  }

  .avatar-xxl.avatar-away:before,
  .avatar-xxl.avatar-offline:before,
  .avatar-xxl.avatar-online:before {
    border-width: 4px;
  }
}

@media only screen and (max-width: 1399.98px) {
  .fc .fc-toolbar-title {
    font-size: 14px !important;
    display: contents;
  }

  .fc .fc-toolbar-title h2 {
    padding-right: 5px;
  }
}

@media only screen and (max-width: 1400px) {

  .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
  .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 180px;
  }
}

@media only screen and (max-width: 1199px) {

  .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
  .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 150px;
  }

  .chat-window .chat-cont-left {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .chat-window .chat-cont-right {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (max-width: 991.98px) {

  .custom_card_inner_styling_verifyOtp_signUp_page {
    top: 120px;
    left: 20px;
    width: 95%;
    padding: 0px 20px;
    position: relative;
  }

  .custom_card_inner_styling_signUp_page {
    position: relative;
    margin-top: 120px;
    left: 5px;
    padding: 0px 10px;
  }

  .Subscription_card_box {
    position: relative;
    margin-top: 130px;
    left: 30px;
    width: 90%;
  }

  .custom_card_styling_Subscription_page {
    margin-top: 80px;
  }

  .sage_mini_logo {
    top: 0;
    right: 0;
    position: absolute;
    background-color: #005d38;
    width: fit-content;
    padding: 10px 10px;
    border-radius: 50%;
    box-shadow: 1px 3px 12px #0000005b;
  }

  .doc_image>.rightside_image {
    display: none;
  }

  .fc .fc-toolbar-title {
    padding-right: 10px;
    display: inline;
  }

  .Request_images_card {
    width: fit-content;
    border-radius: 1rem;
    box-shadow: 1px 3px 8px #00000029;
  }

  .input-verification .styles_react-code-input__CRulA>input:first-child {
    width: 30px !important;
    height: 30px !important;
  }

  .input-verification .styles_react-code-input__CRulA>input {
    width: 30px !important;
    height: 30px !important;
    border-radius: 0px !important;
  }

  .timeslot-modal-section-styling {
    margin-top: 10px;
    height: auto;
  }

  .timeslot-modal-searchfield {
    width: 100%;
    position: relative;
  }

  .doctors-specialites-row {
    margin-top: 0.1rem;
  }

  .Req_text {
    font-size: 14px;
    text-align: center;
    display: grid !important;
    color: #515151;
    font-weight: 500;
  }

  .Req_spec {
    color: #7c7c7c;
    font-size: 12px;
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
  }

  .account-content {
    margin-top: -5px;
  }

  .Bar_char {
    background-color: white;
    border-radius: 8px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 1px;
    padding-right: 1px;
    height: auto;
  }

  .map_style {
    max-width: -webkit-fill-available;
  }

  /* .home-calendar-custom-style {
    max-width: 95%;
  } */
  .track-sidebox {
    display: inline-grid;
    /* float: none; */
  }

  .map-vetical-line-center {
    display: flex;
    border-left: 2px solid rgb(214, 216, 214);
    height: 170px;
    margin-inline-start: 9px;
    margin-top: 10px;
    margin-left: 0px;
  }

  .map-info-box-right {
    margin-top: -171px;
  }

  .mapbox-inner-text_1-right {
    margin-left: 10px;
  }

  .track-rightsidebar-data-right {
    display: contents;
  }

  .small_card {
    width: 24rem;
    margin-top: 200px;
    margin-left: 5px;
  }

  .timeline_headings {
    color: #676767;
    font-weight: 500;
    font-size: 13px;
  }

  .login-left img {
    width: 100%;
  }

  .image_background img {
    width: 80px;
    margin-left: 0px;
  }

  .image_background .rightbar_content {
    float: right;
    display: contents;
    margin-left: 5px;
  }

  .Rightsidebar_tel {
    font-size: 10px;
  }

  .Rightsidebar_tel svg {
    font-size: 10px;
  }

  .Rightsidebar_view {
    font-size: 8px;
  }

  .Rightsidebar_view svg {
    font-size: 10px;
  }

  .req {
    background-color: #ededed;
    /* margin-left: 15px;
    margin-right: 15px; */
    border-radius: 3px;
  }

  .success_button {
    font-size: 10px;
    margin-right: auto;
    padding: 5px 30px 5px 30px;
  }

  .danger_button {
    font-size: 10px;
    margin-right: auto;
    padding: 5px 30px 5px 30px;
  }

  .Patient_info {
    float: right;
    /* margin-right: 80px; */
    font-size: 25px;
    color: #9c9392;
  }

  .header .header-left {
    position: absolute;
    width: 100%;
  }

  .mobile_btn {
    color: #333 !important;
    cursor: pointer;
    display: block;
    font-size: 24px;
    height: 60px;
    left: 0;
    line-height: 60px;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 10;
  }

  #toggle_btn {
    display: none;
  }

  .top-nav-search {
    display: none;
  }

  .login-wrapper .loginbox .login-left {
    padding: 80px 50px;
    width: 50%;
  }

  .login-wrapper .loginbox .login-right {
    padding: 50px;
    width: 50%;
  }

  .sidebar {
    margin-left: -225px;
    width: 144px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
    top: 62px;
  }

  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .chat-window .chat-scroll {
    max-height: calc(100vh - 255px);
  }

  .chat-window .chat-cont-left,
  .chat-window .chat-cont-right {
    flex: 0 0 100%;
    max-width: 100%;
    transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
    width: 100%;
  }

  .chat-window .chat-cont-left {
    border-right: 0;
  }

  .chat-window .chat-cont-right {
    position: absolute;
    right: calc(-100% + -1.875rem);
    top: 0;
  }

  .chat-window .chat-cont-right .chat-header {
    justify-content: start;
  }

  .chat-window .chat-cont-right .chat-header .back-user-list {
    display: block;
  }

  .chat-window .chat-cont-right .chat-header .chat-options {
    margin-left: auto;
  }

  .chat-window.chat-slide .chat-cont-left {
    left: calc(-100% + -1.875rem);
  }

  .chat-window.chat-slide .chat-cont-right {
    right: 0;
  }

  .sidebar-overlay.opened {
    display: block;
  }

  .menu-opened .sidebar {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 575.98px) {

  /* .right_side_function{
  display: none;
} */
  .card {
    margin-bottom: 0.9375rem;
  }

  .page-wrapper>.content {
    padding: 0.9375rem 0.9375rem 0;
  }

  .chat-window {
    margin-bottom: 0.9375rem;
  }

  .card-body {
    padding: 1.25rem;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
  }

  .card-chart .card-body {
    padding: 5px;
  }

  .page-header {
    margin-bottom: 0.9375rem;
  }

  .account-wrapper {
    padding: 0.9375rem;
  }

  .pagination-lg .page-link {
    font-size: 1.2rem;
    padding: 0.5rem 0.625rem;
  }

  .profile-image {
    flex: 0 0 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .profile-user-info {
    text-align: center;
  }

  .profile-btn {
    text-align: center;
  }

  .invoice-details,
  .invoice-payment-details>li span {
    float: left;
    text-align: left;
  }

  .fc-toolbar .fc-right {
    display: inline-block;
    float: none;
    margin: 10px auto 0;
    width: 200px;
    clear: both;
  }

  .fc-toolbar .fc-left {
    float: none;
    margin: 0 auto;
    width: 200px;
  }

  .fc-toolbar .fc-center {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .fc-toolbar .fc-center h2 {
    width: 100%;
  }

  .profile-tab-cont {
    padding-top: 1.25rem;
  }

  .chat-window .chat-cont-right .chat-header .media .media-body {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .custom_card_inner_styling_verifyOtp_signUp_page {
    margin-top: 5px;
    margin-bottom: 10px;
    left: 20px;
    width: 95%;
    padding: 0px 20px;
    position: relative;
  }
  .add-btn {
    font-size: 14px;
    padding: 6px 7px;
  }

  .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-name,
  .chat-window .chat-cont-left .chat-users-list a.media .media-body>div:first-child .user-last-chat {
    max-width: 160px;
  }

  .page-header .breadcrumb {
    display: none;
  }
}

/*Custom media queries*/

@media only screen and (max-width: 767.9px) {
  .small_card {
    margin-top: 0px;
    margin-left: 5px;
  }

  .Calendar_card {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .right_side_function {
    right: -192px;
  }

  .right_side_function_patient {
    right: -188px;
  }

  .right_side_function_report {
    right: -280px;
  }

  .right_side_function-show {
    width: 350px;
  }

  .right_side_function-show-map {
    width: 350px;
  }

  .sidebar_calendar {
    border-top-left-radius: 3rem;
    box-shadow: 0px 0px 3px 2px #ebeaea;
    height: 100%;
  }

  .notification_Name {
    margin-left: 15px;
  }

  /* .notification_image img {
    width: 100px;
    height: 100px;
    border: 1px solid #005d38;
    border-radius: 5rem;
  } */
  .notification-image-box {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1400px) {
  .doc_image>.rightside_image {
    display: none;
  }

  .custom_card_styling_Subscription_page {
    margin-top: 140px;
  }

  .sage_mini_logo {
    top: 10px;
    right: 10px;
    position: absolute;
    background-color: #005d38;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 10px;
    border-radius: 50%;
    box-shadow: 1px 3px 12px #0000005b;
  }
}

@media only screen and (max-width: 1400pz) {
  .doc_image>.rightside_image {
    display: none;
  }
}